import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Baseurl, Baseurl2 } from "../../config/BaseUrl";
import axios from "axios";
import { Affix, Slider, Spin } from "antd";
import SingleProduct from "../../component/product/SingleProduct";
import { MdOutlineCancel } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { BsHandbag } from "react-icons/bs";
import { IoFilter } from "react-icons/io5";
import { BiSort } from "react-icons/bi";
import { getCartTotal } from "../../redux/cart/CartSlice";
import { signout } from "../../redux/athentication/Athentication";
import { AiOutlineSortDescending } from "react-icons/ai";
import { AiOutlineSortAscending } from "react-icons/ai";
import { GoSortAsc } from "react-icons/go";
import Offcanvas from "react-bootstrap/Offcanvas";
import { PiSortAscendingLight } from "react-icons/pi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NoProductFound from "../../component/NoProductFound";
import { FaSearch } from "react-icons/fa";
import { set_checkout_authentication_status } from "../../redux/athentication/Athentication";

const ProductByBrand = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarRef = useRef();
  const ref = useRef();

  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
  } = useSelector((state) => state.cart);
  const { mainHomeSliderTotal } = useSelector((store) => store.slider);
  const { isAuth } = useSelector((state) => state.Athentication);
  const { categoryShow } = useSelector((store) => store.category);
  const { categoryTagShow } = useSelector((store) => store.categorytag);
  const { subcategoryShow } = useSelector((store) => store.subCategories);

  const [search, setSearch] = useState("");
  const [subcatSearchResult, setSubcatSearchResult] = useState([]);
  const [categoryTagSearchResult, setCategoryTagSearchResult] = useState([]);
  const [categorySearchResult, setCategorySearchResult] = useState([]);
  const [ProductSearchResult, setProductSearchResult] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const [minSlideValue, setMinSlideValue] = useState(0);
  const [maxSlideValue, setMaxSlideValue] = useState(1000);
  const [sortPrice, setSortPrice] = useState([]);
  const [sortAlphabeticalOrder, setSortAlphabeticalOrder] = useState([]);
  const [selectedValue, setSelectedValue] = useState([
    minSlideValue,
    maxSlideValue,
  ]);
  const [current_category, setcurrent_category] = useState("");
  const [selected_category_tag, setselected_category_tag] = useState([]);
  const [category_tags, setcategory_tags] = useState([]);
  const [category_products, setcategory_products] = useState([]);
  const [tag_loading, settag_Loading] = useState(true);
  const [product_loading, setproduct_Loading] = useState(true);
  const [filtered_products, setfiltered_products] = useState([]);
  const [allproductLoading, setallproductLoading] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const [gotLimitProduct, setGotLimitProduct] = useState(false);
  const [gotIncreasedLimitProduct, setGotIncreasedLimitProduct] =
    useState(false);

  const [discount_Percentage, setDiscount_Percentage] = useState("10");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [filterShow, setFilterShow] = useState(false);

  const handleFilterClose = () => setFilterShow(false);
  const handleFilterShow = () => setFilterShow(true);

  const [uniqueBrands, setUniqueBrands] = useState([]);
  const [selected_brand, setSelected_brand] = useState([]);

  const onChange = async (value) => {
    setSelectedValue(value);
  };

  const handleFilter = () => {
    setShowFilterMenu(!showFilterMenu);
  };

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems]);

  const discont_array = [
    {
      name: "10% and above",
      percentage: 10,
    },
    {
      name: "20% and above",
      percentage: 20,
    },
    {
      name: "30% and above",
      percentage: 30,
    },
    {
      name: "40% and above",
      percentage: 40,
    },
    {
      name: "50% and above",
      percentage: 50,
    },
    {
      name: "60% and above",
      percentage: 60,
    },
    {
      name: "70% and above",
      percentage: 70,
    },
    {
      name: "80% and above",
      percentage: 80,
    },
    {
      name: "90% and above",
      percentage: 90,
    },
  ];

  const searchchange = (e) => {
    const result = e.target.value;
    setSearch(result);

    if (result.length >= 2) {
      const filter_category = categoryShow
        .filter((category) =>
          category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_sub_category = subcategoryShow
        .filter((sub_category) =>
          sub_category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_category_tag = categoryTagShow
        .filter((category_tag) =>
          category_tag.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);

      setCategorySearchResult(filter_category);
      setSubcatSearchResult(filter_sub_category);
      // setCategoryTagSearchResult(filter_category_tag);
      setShowResults(true);
    }
  };

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const searchsubmit = (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
      setSearch("");
    } else {
      alert("Please type something...");
    }
  };

  const serchclick = () => {
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };

  useEffect(() => {
    if (params.sub_category) {
      const get_sub_Category = async () => {
        const url = `${Baseurl}/api/v1/subcategory/slugurl/${params.sub_category}`;
        const sub_categoryRespo = await axios.get(url);
        if (sub_categoryRespo.data.success) {
          const sub_category_data = sub_categoryRespo.data.subcategory;
          setcurrent_category(sub_category_data);
          const tag_url = `${Baseurl}/api/v1/categorytag/category-tags-by-sub-category/${sub_category_data.name}`;
          const category_tag_Respo = await axios.get(tag_url);

          if (category_tag_Respo.data.success) {
            const category_tag_data = category_tag_Respo.data.ctegoryTags;
            setcategory_tags(category_tag_data);
            settag_Loading(false);
          }

          const product_url = `${Baseurl2}/api/v1/product/all-products-by-sub-category-limit/${sub_category_data.name}`;
          const product_Respo = await axios.get(product_url);

          if (product_Respo.data.success) {
            const product_data = product_Respo.data.products;
            setcategory_products(product_data);
            setfiltered_products(product_data);
            setproduct_Loading(false);
            // Extracting unique brands
            const brands = product_data.map((product) => product.brand);
            const uniqueBrandsSet = new Set(brands);
            const uniqueBrandsArray = Array.from(uniqueBrandsSet);
            setUniqueBrands(uniqueBrandsArray);
          }
        }
      };

      get_sub_Category();
    }
  }, [params.sub_category]);

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setShowFilterMenu(false);
    }
  };

  useEffect(() => {
    if (showFilterMenu) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showFilterMenu]);

  useEffect(() => {
    if (params.category) {
      const getCategory = async () => {
        const url = `${Baseurl}/api/v1/category/slugurl/${params.category}`;
        const categoryRespo = await axios.get(url);
        if (categoryRespo.data.success) {
          const category_data = categoryRespo.data.category;
          setcurrent_category(category_data);
          const tag_url = `${Baseurl}/api/v1/categorytag/category-tags-by-category/${category_data.name}`;
          const category_tag_Respo = await axios.get(tag_url);

          if (category_tag_Respo.data.success) {
            const category_data = category_tag_Respo.data.ctegoryTags;
            setcategory_tags(category_data);
            settag_Loading(false);
          }
          const product_url = `${Baseurl2}/api/v1/product/all-products-by-category-limit/${category_data.name}`;
          const product_Respo = await axios.get(product_url);

          if (product_Respo.data.success) {
            const product_data = product_Respo.data.products;
            // Extracting unique brands
            const brands = product_data.map((product) => product.brand);
            const uniqueBrandsSet = new Set(brands);
            const uniqueBrandsArray = Array.from(uniqueBrandsSet);
            setUniqueBrands(uniqueBrandsArray);
            setcategory_products(product_data);
            setfiltered_products(product_data);
            setproduct_Loading(false);
            setTimeout(() => {
              setGotLimitProduct(true);
            }, 3000);
          }
        }
      };
      getCategory();
    }
  }, [params.category]);

  useEffect(() => {
    if (gotLimitProduct) {
      const getProductAll = async () => {
        const product_url = `${Baseurl2}/api/v1/product/all-products-by-category-limitIncrease/${current_category.name}`;
        const product_Respo = await axios.get(product_url);
        if (product_Respo.data.success) {
          const product_data = product_Respo.data.products;
          setcategory_products(product_data);
          setfiltered_products(product_data);
          setallproductLoading(false);
          // Extracting unique brands
          const brands = product_data.map((product) => product.brand);
          const uniqueBrandsSet = new Set(brands);
          const uniqueBrandsArray = Array.from(uniqueBrandsSet);
          setUniqueBrands(uniqueBrandsArray);
        }
      };
      getProductAll();
    }
  }, [gotLimitProduct]);

  const handletagPress = (e, value) => {
    if (e.target.checked) {
      setselected_category_tag([...selected_category_tag, value]);
    } else {
      setselected_category_tag(
        selected_category_tag.filter((tag) => tag !== value)
      );
    }
    // setFilterShow(false);
  };
  useEffect(() => {
    if (selected_category_tag.length === 0) {
      const products = category_products;
      setfiltered_products(products);
      const prices = products.map(
        (product) => product.packSize[0].PackSizeOptions[0].Price
      );
      const lowestPrice = Math.min(...prices);
      setMinSlideValue(lowestPrice);
      const highestPrice = Math.max(...prices);
      setMaxSlideValue(highestPrice);
    } else {
      const products = category_products.filter((item) =>
        selected_category_tag.includes(item.brand)
      );
      setfiltered_products(products);
      const prices = products.map(
        (product) => product.packSize[0].PackSizeOptions[0].Price
      );
      const lowestPrice = Math.min(...prices);
      setMinSlideValue(lowestPrice);
      const highestPrice = Math.max(...prices);
      setMaxSlideValue(highestPrice);
    }
  }, [selected_category_tag]);

  useEffect(() => {
    if (filterName === "Alphabetically, A-Z") {
      const sortedProducts = filtered_products.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
      setfiltered_products(sortedProducts);
    } else if (filterName === "Alphabetically, Z-A") {
      const products = filtered_products.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setfiltered_products(products);
    }
  }, [filterName]);

  useEffect(() => {
    const getProducts = category_products;
    const slideFilterProduct = getProducts.filter(
      (product) =>
        product.packSize[0].PackSizeOptions[0].Price >= selectedValue[0] &&
        product.packSize[0].PackSizeOptions[0].Price <= selectedValue[1]
    );
    setfiltered_products(slideFilterProduct);
  }, [selectedValue]);

  useEffect(() => {
    const prices = filtered_products.map(
      (product) => product.packSize[0].PackSizeOptions[0].Price
    );
    const lowestPrice = Math.min(...prices);
    setMinSlideValue(lowestPrice);
    const highestPrice = Math.max(...prices);
    setMaxSlideValue(highestPrice);
  }, []);

  const selectFilterChange = (value) => {
    setSortAlphabeticalOrder(value);
  };
  useEffect(() => {
    let sortedProducts = [...filtered_products];
    if (sortAlphabeticalOrder === "title-descending") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
      setfiltered_products(sortedProducts);
    } else if (sortAlphabeticalOrder === "title-ascending") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setfiltered_products(sortedProducts);
    }
  }, [sortAlphabeticalOrder]);

  const selectPriceChange = (value) => {
    setSortPrice(value);
  };
  useEffect(() => {
    let sortedProducts = [...filtered_products];
    if (sortPrice === "High to Low") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return (
          b.packSize[0].PackSizeOptions[0].Price -
          a.packSize[0].PackSizeOptions[0].Price
        );
      });
      setfiltered_products(sortedProducts);
    } else if (sortPrice === "Low to High") {
      sortedProducts = sortedProducts.sort((a, b) => {
        return (
          a.packSize[0].PackSizeOptions[0].Price -
          b.packSize[0].PackSizeOptions[0].Price
        );
      });
      setfiltered_products(sortedProducts);
    }
  }, [sortPrice]);
  const catSearchClick = (category) => {
    if (search !== "") {
      navigate(`/search/${category.superCategory}/${category.slugUrl}`);
      setShowResults(false);
      setSearch("");
    }
  };
  const signoutClick = () => {
    dispatch(signout());
    navigate("/");
  };

  return (
    <>
      <div>
        <div className="topbar-slider clearfix category-mob">
          <div className="container-fluid">
            {/* <div className="marquee-text">
              <div className="top-info-bar d-flex">
                <div className="flex-item center">
                  <Link>
                    <span>
                      <i className="anm anm-truck-l" />
                      info@ewshopping.com
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> FREE SHIPPING ON ALL
                      ORDERS ABOVE ₹500
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-redo-ar" /> EXTENDED RETURN UNTIL 30
                      DAYS
                    </span>
                  </Link>
                </div>
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-worldwide" /> BUY ONLINE BEST DEALS
                      ON ELECTRONICS
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> FREE SHIPPING ON ALL
                      ORDERS ABOVE ₹500
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-redo-ar" /> EXTENDED RETURN UNTIL 30
                      DAYS
                    </span>
                  </Link>
                </div>
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-worldwide" /> BUY ONLINE STYLISH
                      OUTFITS & LATEST TRENDS
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> FREE SHIPPING ON ALL
                      ORDERS ABOVE ₹500
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-redo-ar" /> EXTENDED RETURN UNTIL 30
                      DAYS
                    </span>
                  </Link>
                </div>
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-worldwide" /> BUY ONLINE FASHIONABLE
                      APPAREL
                    </span>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="top-header category-mob1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6 col-sm-6 col-md-3 col-lg-4 text-left">
                <a to="tel:401234567890">info@ewshopping.com</a>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-center d-none d-md-block">
                Free shipping on all orders over ₹500{" "}
                <Link to="/" className="text-link ms-1">
                  Shop now
                </Link>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-4 text-right d-flex align-items-center justify-content-end">
                <div className="select-wrap language-picker float-start buttndesign">
                  <Link to="https://seller.ewshopping.com" target="_blank">
                    <div className="option english">
                      <span>Sell On Ewshopping</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <Affix offsetTop={0}>
          {/* <div>
            <header
              className="header   header-1 header-fixed desktopVersion"
              style={{ background: "#2f415d" }}
            >

              <div className="container  " style={{ paddingTop: "15px" }}>
                <div className="row">
                  <div className="logo col-5 col-sm-3 col-md-2 col-lg-3 align-self-center is-fixed desktopVersion">
                    <Link className="logoImg" to="/">
                      <img
                        src="/assets/images/logonew14.png"
                        alt="EwShopping"
                        title="EwShopping"
                        className="imglogo"
                        width="200px"
                      />
                    </Link>
                  </div>

                  <div className="col-1 col-sm-6 col-md-1 col-lg-6 align-self-center d-menu-col ">
                    <nav className="navigation " id="AccessibleNav">
                      <div
                        className="search-body "
                        style={{
                          border: "1.5px solid #2f415d",
                          borderRadius: "5px",
                        }}
                        ref={ref}
                      >
                        <form
                          className="form minisearch"
                          id="header-search"
                          onSubmit={(e) => searchsubmit(e)}
                        >
                          <div
                            className="d-flex searchField"
                            style={{ position: "relative" }}
                          >
                            <div className="input-box d-flex fl-1">
                              <input
                                type="text"
                                className="input-text border-start-0 border-end-0"
                                placeholder="Search for products..."
                                value={search}
                                onChange={searchchange}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              <button
                                type="submit"
                                onClick={serchclick}
                                className="action search d-flex-justify-center btn rounded-start-0"
                              >
                                <span style={{ color: "white" }}>
                                  {" "}
                                  <i className="icon anm anm-search-l" />
                                </span>
                              </button>
                            </div>
                          </div>
                          {showResults && (
                            <>
                              <div className="serchsugist">
                                {categorySearchResult.length > 0 && (
                                  <ul className="">
                                    {categorySearchResult &&
                                      categorySearchResult.map(
                                        (category, index) => (
                                          <li className="item" key={index}>
                                            <div
                                              className="mini-list-item d-flex align-items-center w-100 clearfix"
                                              onClick={() =>
                                                catSearchClick(category)
                                              }
                                            >
                                              <div className="mini-image text-center">
                                                <Link className="item-link">
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category.desktopImage
                                                    }
                                                    src={category.desktopImage}
                                                    alt={category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link className="item-title">
                                                    {category.name}
                                                  </Link>
                                                </div>

                                                <Link>
                                                  <div className="product-review d-flex align-items-center justify-content-start">
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                        color: "#2f415d",
                                                      }}
                                                    >
                                                      in{" "}
                                                      {category.superCategory}
                                                    </span>
                                                  </div>
                                                </Link>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {subcatSearchResult.length > 0 && (
                                  <ul>
                                    {subcatSearchResult &&
                                      subcatSearchResult.map(
                                        (sub_category, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                                  onClick={() =>
                                                    setShowResults(false)
                                                  }
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      sub_category.desktopImage
                                                    }
                                                    src={
                                                      sub_category.desktopImage
                                                    }
                                                    alt={sub_category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                                    onClick={() =>
                                                      setShowResults(false)
                                                    }
                                                  >
                                                    {sub_category.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in{" "}
                                                    {sub_category.superCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {categoryTagSearchResult.length > 0 && (
                                  <ul>
                                    {categoryTagSearchResult &&
                                      categoryTagSearchResult.map(
                                        (category_tag, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/tag-search/${category_tag.slugUrl}`}
                                                  onClick={() =>
                                                    setShowResults(false)
                                                  }
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category_tag.desktopImage
                                                    }
                                                    src={
                                                      category_tag.desktopImage
                                                    }
                                                    alt={category_tag.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/tag-search/${category_tag.slugUrl}`}
                                                    onClick={() =>
                                                      setShowResults(false)
                                                    }
                                                  >
                                                    {category_tag.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in{" "}
                                                    {category_tag.SubCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </nav>
                  </div>

                  <div className="col-7 col-sm-3 col-md-9 col-lg-3 align-self-center icons-col text-right desktopVersion">
                    <div className="search-parent iconset ">
                      <div className="site-search mobileVersion" title="Search">
                        <Link
                          to=""
                          className="search-icon clr-none"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#search-drawer"
                        >
                          <i className="hdr-icon icon anm anm-search-l" />
                        </Link>
                      </div>

                      <div
                        className="search-drawer offcanvas offcanvas-top"
                        tabIndex={-1}
                        id="search-drawer"
                      >
                        <div
                          className="container"
                          style={{ zIndex: "9999 !important" }}
                        >
                          <div className="search-header d-flex-center justify-content-between mb-3">
                            <h3 className="title m-0">
                              What are you looking for?
                            </h3>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            />
                          </div>
                          <div className="search-body">
                            <form
                              className="form minisearch"
                              id="header-search"
                              onSubmit={(e) => searchsubmit(e)}
                            >
                              <div className="d-flex searchField">
                              
                                <div className="input-box d-flex fl-1">
                                  <input
                                    type="text"
                                    className="input-text border-start-0 border-end-0"
                                    placeholder="Search for products..."
                                    value={search}
                                    onChange={searchchange}
                                  />
                                  <button
                                    type="submit"
                                    onClick={serchclick}
                                    className="action search d-flex-justify-center btn rounded-start-0"
                                  >
                                    <span style={{ color: "white" }}>
                                      {" "}
                                      <i className="icon anm anm-search-l" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                             
                              <div className="search-products">
                                {subcatSearchResult.length === 0 &&
                                  categorySearchResult.length === 0 &&
                                  categoryTagSearchResult.length === 0 && (
                                    <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                      <li className="item empty w-100 text-center text-muted d-block">
                                        You don't have any items in your search.
                                      </li>
                                    </ul>
                                  )}

                                {categorySearchResult.length > 0 && (
                                  <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                    {categorySearchResult &&
                                      categorySearchResult.map(
                                        (category, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category.desktopImage
                                                    }
                                                    src={category.desktopImage}
                                                    alt={category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                  >
                                                    {category.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in {category.superCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {subcatSearchResult.length > 0 && (
                                  <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                    {subcatSearchResult &&
                                      subcatSearchResult.map(
                                        (sub_category, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      sub_category.desktopImage
                                                    }
                                                    src={
                                                      sub_category.desktopImage
                                                    }
                                                    alt={sub_category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                                  >
                                                    {sub_category.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in {sub_category.category}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {categoryTagSearchResult.length > 0 && (
                                  <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                    {categoryTagSearchResult &&
                                      categoryTagSearchResult.map(
                                        (category_tag, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/tag-search/${category_tag.slugUrl}`}
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category_tag.desktopImage
                                                    }
                                                    src={
                                                      category_tag.desktopImage
                                                    }
                                                    alt={category_tag.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/tag-search/${category_tag.slugUrl}`}
                                                  >
                                                    {category_tag.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in{" "}
                                                    {category_tag.SubCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="account-parent iconset">
                      <div className="account-link" title="Account">
                        <i className="hdr-icon icon anm anm-user-al" />
                      </div>
                      <div id="accountBox">
                        <div className="customer-links">
                          <ul className="m-0">
                            {isAuth === true ? (
                              <>
                                <li>
                                  <Link to="/my-account">
                                    <i className="icon anm anm-user-cil" />
                                    My Account
                                  </Link>
                                </li>
                                <li onClick={() => signoutClick()}>
                                  <i className="icon anm anm-sign-out-al" />
                                  Sign out
                                </li>
                              </>
                            ) : (
                              <>
                                <li
                                  onClick={() => {
                                    dispatch(
                                      set_checkout_authentication_status(0)
                                    );
                                    navigate("/login");
                                  }}
                                >
                                  <Link to="/login">
                                    <i className="icon anm anm-user-cil" />
                                    Sign In
                                  </Link>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                   
               
                    <div className="header-cart iconset" title="Cart">
                      <Link
                        className="header-cart btn-minicart clr-none"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#minicart-drawer"
                      >
                        <i className="hdr-icon icon anm anm-cart-l" />
                        <span className="cart-count">{CartItems.length}</span>
                      </Link>

                      <div
                        id="minicart-drawer"
                        className="minicart-right-drawer offcanvas offcanvas-end"
                        tabIndex={-1}
                      >
                        {CartItems.length === 0 ? (
                          <>
                            <div
                              id="cartEmpty"
                              className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                            >
                              <div className="minicart-header d-flex-center justify-content-between w-100">
                                <h4 className="fs-6">
                                  Your cart ({CartItems.length} Items)
                                </h4>
                                <button
                                  className="close-cart border-0"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  <i
                                    className="icon anm anm-times-r"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Close"
                                  />
                                </button>
                              </div>
                              <div className="cartEmpty-content mt-4">
                                <i className="icon anm anm-cart-l fs-1 text-muted" />
                                <p className="my-3">No Products in the Cart</p>
                                <span
                                  className="btn btn-primary cart-btn"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                  onClick={() => navigate("/")}
                                >
                                  Continue shopping
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div id="cart-drawer" className="block block-cart">
                              <div className="minicart-header">
                                <button
                                  className="close-cart border-0"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  <i
                                    className="icon anm anm-times-r"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Close"
                                  />
                                </button>
                                <h4 className="fs-6">
                                  Your cart ({CartItems.length} Items)
                                </h4>
                              </div>
                              <div className="minicart-content">
                                <ul className="m-0 clearfix">
                                  {CartItems &&
                                    CartItems.map((item, index) => (
                                      <li
                                        className="item d-flex justify-content-center align-items-center"
                                        key={index}
                                      >
                                        <span className="product-image rounded-3">
                                          <img
                                            className="blur-up lazyload"
                                            data-src={item.thumbnail}
                                            src={item.thumbnail}
                                            alt="product"
                                            title="Product"
                                            width={120}
                                            height={170}
                                          />
                                        </span>
                                        <div className="product-details">
                                          <span
                                            className="product-title limited-paragraph"
                                            onClick={() => {
                                              if ("slugurl" in item) {
                                                navigate(
                                                  `/product-info/${item.slugurl}`
                                                );
                                              }
                                            }}
                                            data-bs-dismiss="offcanvas"
                                            aria-label="Close"
                                          >
                                            {item.ProductName}
                                          </span>
                                          <div className="variant-cart my-2 limited-paragraph">
                                            {item.packSizeOptionName} /{" "}
                                            {item.packSizeName}
                                          </div>
                                          <div className="priceRow">
                                            <div className="product-price">
                                              <span className="price">
                                                {" "}
                                                ₹{item.Price}
                                              </span>{" "}
                                              x{" "}
                                              <span className="price">
                                                {" "}
                                                {item.cart_Quentity}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              <div className="minicart-bottom">
                                <div className="shipinfo">
                                  {amountToGetfeeDelivery !== 0 && (
                                    <div className="progress mb-2">
                                      <div
                                        className="progress-bar progress-bar-striped progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow={70}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{
                                          width: `${amountToGetfeeDeliveryPercentage}%`,
                                        }}
                                      >
                                        {amountToGetfeeDeliveryPercentage}%
                                      </div>
                                    </div>
                                  )}

                                  {amountToGetfeeDeliveryPercentage === 0 ? (
                                    <>
                                      <div className="freeShipMsg">
                                        <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                        Only{" "}
                                        <span
                                          className="money"
                                          data-currency-usd=" ₹199.00"
                                          data-currency="USD"
                                        >
                                          {" "}
                                          ₹{amountToGetfeeDelivery}
                                        </span>{" "}
                                        away from
                                        <b>Free Shipping</b>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="freeShipMsg d-block">
                                        <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                        Congrats! You are eligible for
                                        <b>Free Shipping</b>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="subtotal clearfix my-3">
                                  <div className="totalInfo clearfix mb-1 d-block">
                                    <span>Total:</span>
                                    <span className="item product-price">
                                      {" "}
                                      ₹{TotalPrice}
                                    </span>
                                  </div>
                                  <div className="totalInfo clearfix mb-1 d-block">
                                    <span>Handlling Fee:</span>
                                    <span className="item product-price">
                                      {" "}
                                      ₹{HandllingFee}
                                    </span>
                                  </div>
                                  <div className="totalInfo clearfix">
                                    <span>Netpayable:</span>
                                    <span className="item product-price">
                                      {" "}
                                      ₹{TotalAmount}
                                    </span>
                                  </div>
                                </div>
                              
                                <div className="minicart-action d-flex mt-3">
                                  <span
                                    onClick={() => navigate("/check-out")}
                                    className="proceed-to-checkout btn btn-primary w-50 me-1"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    Check Out
                                  </span>
                                  <span
                                    onClick={() => navigate("/cart")}
                                    className="cart-btn btn btn-secondary w-50 ms-1"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    View Cart
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="header-cart iconset" title="Cart">
                      <Link
                        className="header-cart btn-minicart clr-none"
                        to="https://seller.ewshopping.com"
                        target="_blank"
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            fontWeight: "600",
                          }}
                        >
                          Sell On Ewshopping
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div> */}
        </Affix>
        <div className="desktopHeader" style={{ backgroundColor: "#2F415D" }}>
        <div className="leftHeaderDesktop">
          <div>
            <div className="logo col-5 col-sm-3 col-md-2 col-lg-3 align-self-center is-fixed desktopVersion">
              <Link className="logoImg" to="/">
                <img
                  src="/assets/images/logonew14.png"
                  alt="EwShopping"
                  title="EwShopping"
                  className="imglogo"
                />
              </Link>
            </div>
          </div>
          {
              mainHomeSliderTotal && mainHomeSliderTotal.map((indCat) =>
                <Link to={`/category/${indCat.slugUrl}`} >
              <h6 className="categorynametext">{indCat.name.toUpperCase()}</h6>
            </Link>           
              )}  
        </div>
        <div className="rightHeaderDesktop">
          <div
            className="inputDivisionDesktop"
            onSubmit={(e) => searchsubmit(e)}
          >
            <span>
              <CiSearch
                style={{ fontSize: "1.5rem" }}
                type="submit"
                onClick={serchclick}
              />
            </span>
            <input
              placeholder="search for products...."
              type="text"
              value={search}
              onChange={searchchange}
            />
          </div>
          {showResults && (
            <>
              <div className="serchsugist">
                {categorySearchResult.length > 0 && (
                  <ul className="">
                    {categorySearchResult &&
                      categorySearchResult.map((category, index) => (
                        <li className="item" key={index}>
                          <div
                            className="mini-list-item d-flex align-items-center w-100 clearfix"
                            onClick={() => catSearchClick(category)}
                          >
                            <div className="mini-image text-center">
                              <Link className="item-link">
                                <img
                                  className="blur-up lazyload"
                                  data-src={category.desktopImage}
                                  src={category.desktopImage}
                                  alt={category.name}
                                  title="product"
                                  width={120}
                                  height={170}
                                />
                              </Link>
                            </div>
                            <div className="ms-3 details text-left">
                              <div className="product-name">
                                <Link className="item-title">
                                  {category.name}
                                </Link>
                              </div>

                              <Link>
                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#2f415d",
                                    }}
                                  >
                                    in {category.superCategory}
                                  </span>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                )}
                {subcatSearchResult.length > 0 && (
                  <ul>
                    {subcatSearchResult &&
                      subcatSearchResult.map((sub_category, index) => (
                        <li className="item" key={index}>
                          <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                            <div className="mini-image text-center">
                              <Link
                                className="item-link"
                                to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                onClick={() => setShowResults(false)}
                              >
                                <img
                                  className="blur-up lazyload"
                                  data-src={sub_category.desktopImage}
                                  src={sub_category.desktopImage}
                                  alt={sub_category.name}
                                  title="product"
                                  width={120}
                                  height={170}
                                />
                              </Link>
                            </div>
                            <div className="ms-3 details text-left">
                              <div className="product-name">
                                <Link
                                  className="item-title"
                                  to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                  onClick={() => setShowResults(false)}
                                >
                                  {sub_category.name}
                                </Link>
                              </div>

                              <div className="product-review d-flex align-items-center justify-content-start">
                                <span
                                  style={{
                                    fontWeight: "600",
                                    color: "#2f415d",
                                  }}
                                >
                                  in {sub_category.superCategory}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                )}
                {categoryTagSearchResult.length > 0 && (
                  <ul>
                    {categoryTagSearchResult &&
                      categoryTagSearchResult.map((category_tag, index) => (
                        <li className="item" key={index}>
                          <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                            <div className="mini-image text-center">
                              <Link
                                className="item-link"
                                to={`/tag-search/${category_tag.slugUrl}`}
                                onClick={() => setShowResults(false)}
                              >
                                <img
                                  className="blur-up lazyload"
                                  data-src={category_tag.desktopImage}
                                  src={category_tag.desktopImage}
                                  alt={category_tag.name}
                                  title="product"
                                  width={120}
                                  height={170}
                                />
                              </Link>
                            </div>
                            <div className="ms-3 details text-left">
                              <div className="product-name">
                                <Link
                                  className="item-title"
                                  to={`/tag-search/${category_tag.slugUrl}`}
                                  onClick={() => setShowResults(false)}
                                >
                                  {category_tag.name}
                                </Link>
                              </div>

                              <div className="product-review d-flex align-items-center justify-content-start">
                                <span
                                  style={{
                                    fontWeight: "600",
                                    color: "#2f415d",
                                  }}
                                >
                                  in {category_tag.SubCategory}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </>
          )}

          <div className="accountpiecesDesk account-parent iconset">
            {/* <LuUser className="accountpiecesDeskicon  anm-user-al" />
              <span className="accountpiecesDesktext anm anm-user-al">Profile</span> */}
            <div className="account-link" title="Account">
              <i className="hdr-icon icon anm anm-user-al" />
            </div>
            <div id="accountBox">
              <div className="customer-links">
                <ul className="m-0">
                  {isAuth === true ? (
                    <>
                      <li>
                        <Link to="/my-account">
                          <i className="icon anm anm-user-cil" />
                          My Account
                        </Link>
                      </li>
                      <li onClick={() => signoutClick()}>
                        <i className="icon anm anm-sign-out-al" />
                        Sign out
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        onClick={() => {
                          dispatch(set_checkout_authentication_status(0));
                          navigate("/login");
                        }}
                      >
                        <Link to="/login">
                          <i className="icon anm anm-user-cil" />
                          Sign In
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div>
            {/* <MdOutlineShoppingBag className="accountpiecesDeskicon" />
              <span className="accountpiecesDesktext">Bag</span> */}
            <div className="accountpiecesDesk header-cart iconset" title="Cart">
              <Link
                className="header-cart btn-minicart clr-none"
                data-bs-toggle="offcanvas"
                data-bs-target="#minicart-drawer"
              >
                <i className="hdr-icon icon anm anm-cart-l" />
                <span className="cart-count">{CartItems.length}</span>
              </Link>

              <div
                id="minicart-drawer"
                className="minicart-right-drawer offcanvas offcanvas-end"
                tabIndex={-1}
              >
                {CartItems.length === 0 ? (
                  <>
                    <div
                      id="cartEmpty"
                      className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                    >
                      <div className="minicart-header d-flex-center justify-content-between w-100">
                        <h4 className="fs-6">
                          Your cart ({CartItems.length} Items)
                        </h4>
                        <button
                          className="close-cart border-0"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <i
                            className="icon anm anm-times-r"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Close"
                          />
                        </button>
                      </div>
                      <div className="cartEmpty-content mt-4">
                        <i className="icon anm anm-cart-l fs-1 text-muted" />
                        <p className="my-3">No Products in the Cart</p>
                        <span
                          className="btn btn-primary cart-btn"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={() => navigate("/")}
                        >
                          Continue shopping
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div id="cart-drawer" className="block block-cart">
                      <div className="minicart-header">
                        <button
                          className="close-cart border-0"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <i
                            className="icon anm anm-times-r"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Close"
                          />
                        </button>
                        <h4 className="fs-6">
                          Your cart ({CartItems.length} Items)
                        </h4>
                      </div>
                      <div className="minicart-content">
                        <ul className="m-0 clearfix">
                          {CartItems &&
                            CartItems.map((item, index) => (
                              <li
                                className="item d-flex justify-content-center align-items-center"
                                key={index}
                              >
                                <span className="product-image rounded-3">
                                  <img
                                    className="blur-up lazyload"
                                    data-src={item.thumbnail}
                                    src={item.thumbnail}
                                    alt="product"
                                    title="Product"
                                    width={120}
                                    height={170}
                                  />
                                </span>
                                <div className="product-details">
                                  <span
                                    className="product-title limited-paragraph"
                                    onClick={() => {
                                      if ("slugurl" in item) {
                                        navigate(
                                          `/product-info/${item.slugurl}`
                                        );
                                      }
                                    }}
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    {item.ProductName}
                                  </span>
                                  <div className="variant-cart my-2 limited-paragraph">
                                    {item.packSizeOptionName} /{" "}
                                    {item.packSizeName}
                                  </div>
                                  <div className="priceRow">
                                    <div className="product-price">
                                      <span className="price">
                                        {" "}
                                        ₹{item.Price}
                                      </span>{" "}
                                      x{" "}
                                      <span className="price">
                                        {" "}
                                        {item.cart_Quentity}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="minicart-bottom">
                        <div className="shipinfo">
                          {amountToGetfeeDelivery !== 0 && (
                            <div className="progress mb-2">
                              <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                aria-valuenow={70}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                style={{
                                  width: `${amountToGetfeeDeliveryPercentage}%`,
                                }}
                              >
                                {amountToGetfeeDeliveryPercentage}%
                              </div>
                            </div>
                          )}

                          {amountToGetfeeDeliveryPercentage === 0 ? (
                            <>
                              <div className="freeShipMsg">
                                <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                Only{" "}
                                <span
                                  className="money"
                                  data-currency-usd=" ₹199.00"
                                  data-currency="USD"
                                >
                                  {" "}
                                  ₹{amountToGetfeeDelivery}
                                </span>{" "}
                                away from
                                <b>Free Shipping</b>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="freeShipMsg d-block">
                                <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                Congrats! You are eligible for
                                <b>Free Shipping</b>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="subtotal clearfix my-3">
                          <div className="totalInfo clearfix mb-1 d-block">
                            <span>Total:</span>
                            <span className="item product-price">
                              {" "}
                              ₹{TotalPrice}
                            </span>
                          </div>
                          <div className="totalInfo clearfix mb-1 d-block">
                            <span>Handlling Fee:</span>
                            <span className="item product-price">
                              {" "}
                              ₹{HandllingFee}
                            </span>
                          </div>
                          <div className="totalInfo clearfix">
                            <span>Netpayable:</span>
                            <span className="item product-price">
                              {" "}
                              ₹{TotalAmount}
                            </span>
                          </div>
                        </div>

                        <div className="minicart-action d-flex mt-3">
                          <span
                            onClick={() => navigate("/check-out")}
                            className="proceed-to-checkout btn btn-primary w-50 me-1"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            Check Out
                          </span>
                          <span
                            onClick={() => navigate("/cart")}
                            className="cart-btn btn btn-secondary w-50 ms-1"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            View Cart
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="accountpiecesDesk"
            onClick={() =>
              window.open(`https://seller.ewshopping.com`, "_blank")
            }
          >
            <span className="accountpiecesDesktext">Sell on EWShopping</span>
          </div>
        </div>
      </div>
      </div>
      <div className="mainshirtscontainer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="shirtscontainer">
            <FaArrowLeft size={22} onClick={() => navigate("/")} />
            {/* <div style={{ marginLeft: "15px" }}>
              <div
                className="shirtstext"
                style={{ fontWeight: "600", fontSize: "18px" }}
              >
                {params.category}
              </div>
              <div
                className="itemstext"
                style={{ fontWeight: "600", fontSize: "14px" }}
              >
                {filtered_products.length} items
              </div>
            </div> */}
          </div>
          <div className="iconmaincontainer">
            <span>
              {" "}
              <CiSearch
                className="cisearch"
                onClick={() => navigate("/searchproductpage")}
              />
            </span>

            <span onClick={()=>navigate('/cart')}>
              <BsHandbag
                className="bshandbag"
                style={{ position: "relative" }}
              />
              <span
                style={{
                  position: "absolute",
                  backgroundColor: "black",
                  color: "#fff",
                  top: "5px",
                  right: "20px",
                  padding: "0px 5px",
                  borderRadius: "70%",
                }}
              >
                {CartItems.length}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div id="page-content" style={{marginTop:"35px"}}>
        <div className="container">
          {/* <div className="collection-slider-6items gp10 slick-arrow-dots sub-collection section pt-0">
            <SliderSubCategories />
          </div> */}
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 sidebar sidebar-bg filterbar">
              <div className="closeFilter d-block d-lg-none">
                <i className="icon anm anm-times-r" />
              </div>
              <div className="sidebar-tags sidebar-sticky clearfix">
                {/* <div className="sidebar-widget filterBox filter-widget">
                  <div className="widget-title">
                    <h2>Filter By</h2>
                  </div>
                  <div className="widget-content filterby filterDD">
                    <ul className="items tags-list d-flex-wrap">
                      <li className="item">
                        <Link to="#;" className="rounded-5">
                          <span className="filter-value">Women</span>
                          <i className="icon anm anm-times-r" />
                        </Link>
                      </li>
                      <li className="item">
                        <Link to="#;" className="rounded-5">
                          <span className="filter-value">Blue</span>
                          <i className="icon anm anm-times-r" />
                        </Link>
                      </li>
                      <li className="item">
                        <Link to="#;" className="rounded-5">
                          <span className="filter-value">XL</span>
                          <i className="icon anm anm-times-r" />
                        </Link>
                      </li>
                    </ul>
                    <Link to="#;" className="btn btn-sm brd-link">
                      Clear All
                    </Link>
                  </div>
                </div> */}
                {uniqueBrands.length > 0 && (
                  <div className="sidebar-widget clearfix categories filterBox">
                    <div className="widget-title">
                      <h2>Shop By Brands</h2>
                    </div>
                    <div className="widget-content filterDD">
                      <ul className="sidebar-categories scrollspy morelist clearfix">
                        {uniqueBrands &&
                          uniqueBrands.map((tag, index) => (
                            <li key={index}>
                              <input
                                type="checkbox"
                                id={`tag-${index}`}
                                name="avone"
                                value={tag}
                                checked={selected_category_tag.includes(tag)}
                                onChange={(e) => handletagPress(e, tag)}
                              />
                              <label htmlFor={`tag-${index}`}>
                                <span />
                                {tag}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}
                {filtered_products.length > 0 && (
                  <div className="sidebar-widget filterBox ">
                    <div className="widget-title">
                      <h2>Price</h2>
                    </div>
                    <form
                      className="widget-content price-filter filterDD"
                      action="#"
                      method="post"
                    >
                      <div id="slider-range" className="mt-2" />
                      <div className="row">
                        <div className="col-12">
                          <Slider
                            range
                            step={10}
                            min={Number(minSlideValue)}
                            max={Number(maxSlideValue)}
                            onChange={onChange}
                            value={selectedValue}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                )}
                {/* <div className="sidebar-widget clearfix categories filterBox">
                  <div className="widget-title">
                    <h2>DISCOUNT RANGE</h2>
                  </div>
                  <div className="widget-content filterDD">
                    <ul className="sidebar-categories scrollspy morelist clearfix">
                      {discont_array.map((data, index) => (
                        <li key={index}>
                          <input
                            type="checkbox"
                            id={data.name}
                            name="discount"
                            value={discount_Percentage}
                            checked={discount_Percentage === data.percentage}
                            onChange={(e) =>
                              setDiscount_Percentage(data.percentage)
                            }
                          />
                          <label>
                            <span />
                            {data.name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div> */}
                {/*End Banner*/}
              </div>
            </div>
            {/*End Sidebar*/}
            {/*Products*/}
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 main-col">
              {/*Toolbar*/}
              <div className="toolbar toolbar-wrapper shop-toolbar mt-3">
                <div className="row align-items-center">
                  {/* <div className="col-4 col-sm-2 col-md-4 col-lg-4 text-left filters-toolbar-item d-flex order-1 order-sm-0">
                    <button
                      type="button"
                      onClick={() => handleFilter()}
                      className="btn btn-filter icon anm anm-sliders-hr d-inline-flex d-lg-none me-2"
                    >
                      <span className="d-none">Filter</span>
                    </button>
                  </div> */}
                  {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-right filters-toolbar-item d-flex justify-content-end order-2 order-sm-2">
                    <div className="filters-item d-flex align-items-center ms-2 ms-lg-3">
                      <label
                        htmlFor="SortBy"
                        className="mb-0 me-2 text-nowrap d-none"
                      >
                        Sort by:
                      </label>
                      <select
                        name="SortBy"
                        id="SortBy"
                        className="filters-toolbar-sort"
                        onChange={(e) => selectFilterChange(e)}
                      >
                        <option value="featured " selected="selected" hidden>
                          Select filter
                        </option>
                        <option value="title-ascending">
                          Alphabetically, A-Z
                        </option>
                        <option value="title-descending">
                          Alphabetically, Z-A
                        </option>
                      </select>
                    </div>
                  </div> */}
                </div>
              </div>
              {/*End Toolbar*/}
              {/*Product Grid*/}

              {product_loading === true ? (
                <>
                  <div className="example">
                    <Spin />
                  </div>
                </>
              ) : (
                <>
                  {filtered_products.length > 0 ? (
                    <>
                      <div className="grid-products grid-view-items">
                        <div className="row col-row product-options row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                          {filtered_products &&
                            filtered_products.map((product, index) => (
                              <SingleProduct key={index} product={product} />
                            ))}
                          {product_loading === false &&
                            allproductLoading === true && (
                              <div className="example_new">
                                <Spin />
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="no_product_container">
                        <NoProductFound />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footermaincontainer">
        <div>
          <div className="footermaincontainer1">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={handleShow}
            >
              <BiSort className="bisort" />
              <div className="sorttext">SORT</div>
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="bottom"
              style={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              <Offcanvas.Header closeButton style={{ padding: "20px 20px" }}>
                <Offcanvas.Title
                  style={{ fontWeight: "600", fontSize: "16px", margin: "0px" }}
                >
                  Sort By:
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div style={{ padding: "5px" }}>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectPriceChange("High to Low");
                      setShow(false);
                    }}
                  >
                    <GoSortAsc size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Price: High to Low
                    </h6>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectPriceChange("Low to High");
                      setShow(false);
                    }}
                  >
                    <PiSortAscendingLight size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Price: Low to High
                    </h6>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectFilterChange("title-ascending");
                      setShow(false);
                    }}
                  >
                    <AiOutlineSortAscending size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Alphabetically, A-Z
                    </h6>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      selectFilterChange("title-descending");
                      setShow(false);
                    }}
                  >
                    <AiOutlineSortDescending size={20} />
                    <h6
                      style={{
                        color: "#666",
                        fontWeight: "600",
                        paddingLeft: "5px",
                      }}
                    >
                      Alphabetically, Z-A
                    </h6>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => handleFilterShow()}
            >
              <IoFilter className="iofilter" />
              <div className="sorttext">FILTER</div>
            </div>
            <Offcanvas show={filterShow} onHide={handleFilterClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  style={{ fontSize: "18px", fontWeight: "600", margin: "0px" }}
                >
                  CATEGORIES
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ul className="sidebar-categories scrollspy morelist clearfix">
                  {tag_loading === false &&
                    category_tags &&
                    category_tags.map((tag, index) => (
                      <li key={index} style={{ padding: "3px 0px" }}>
                        <input
                          type="checkbox"
                          id={`tag-${index}`}
                          name="avone"
                          value={tag.name}
                          checked={selected_category_tag.includes(tag.name)}
                          onChange={(e) => handletagPress(e, tag.name)}
                        />
                        <label
                          htmlFor={`tag-${index}`}
                          className="ps-1"
                          style={{ fontSize: "18px" }}
                        >
                          <span />
                          {tag.name.length > 20
                            ? `${tag.name.slice(0, 20)}...`
                            : tag.name}
                        </label>
                      </li>
                    ))}
                </ul>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                    borderBottom: ".5px solid #ccc",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "20px",
                    }}
                  >
                    PRICE:
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Slider
                      range
                      step={10}
                      min={Number(minSlideValue)}
                      max={Number(maxSlideValue)}
                      onChange={onChange}
                      value={selectedValue}
                    />
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductByBrand;
