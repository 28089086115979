
import React from "react";
import ClothingSupercat from "./components/ClothingSupercat";
import ElectronicSubcat from "./components/ElectronicSubcat";

const Product_info_mobile = ({ productifo }) => {
  const renderSwitch = (param) => {
    switch (param) {
      case "Clothing": {
        return (
          <>
            <ClothingSupercat productifo={productifo} />
          </>
        );
      }
      case "Electronics": {
        return (
          <>
            <ElectronicSubcat productifo={productifo} />
          </>
        );
      }

      default:
        return <>{/* <ClothingSupercat /> */}</>;
    }
  };

  return <>{renderSwitch(productifo.superCategory)}</>;
};

export default Product_info_mobile;
