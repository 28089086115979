import React from "react";

const Electronic_Supercategory = ({ productifo }) => {
  return (
    <>
      <tbody>
        {productifo.electronics.in_the_box !== "" && (
          <tr>
            <th>in the box</th>
            <td>{productifo.electronics.in_the_box}</td>
          </tr>
        )}
        {productifo.electronics.model_number !== "" && (
          <tr>
            <th>model number</th>
            <td>{productifo.electronics.model_number}</td>
          </tr>
        )}

        {productifo.electronics.model_name !== "" && (
          <tr>
            <th>model name</th>
            <td>{productifo.electronics.model_name}</td>
          </tr>
        )}
        {productifo.electronics.color !== "" && (
          <tr>
            <th>color</th>
            <td>{productifo.electronics.color}</td>
          </tr>
        )}
        {productifo.electronics.browse_type !== "" && (
          <tr>
            <th>browse type</th>
            <td>{productifo.electronics.browse_type}</td>
          </tr>
        )}
        {productifo.electronics.sim_type !== "" && (
          <tr>
            <th>sim type</th>
            <td>{productifo.electronics.sim_type}</td>
          </tr>
        )}
        {productifo.electronics.hybrid_sim_slot !== "" && (
          <tr>
            <th>hybrid sim slot</th>
            <td>{productifo.electronics.hybrid_sim_slot}</td>
          </tr>
        )}
        {productifo.electronics.touchscreen !== "" && (
          <tr>
            <th>touch screen</th>
            <td>{productifo.electronics.touchscreen}</td>
          </tr>
        )}
        {productifo.electronics.otg_compatible !== "" && (
          <tr>
            <th>otg compatible</th>
            <td>{productifo.electronics.otg_compatible}</td>
          </tr>
        )}
        {productifo.electronics.quick_charging !== "" && (
          <tr>
            <th>quick charging</th>
            <td>{productifo.electronics.quick_charging}</td>
          </tr>
        )}
        {productifo.electronics.sar_value !== "" && (
          <tr>
            <th>sar value</th>
            <td>{productifo.electronics.sar_value}</td>
          </tr>
        )}
        {productifo.electronics.display_size !== "" && (
          <tr>
            <th>display size</th>
            <td>{productifo.electronics.display_size}</td>
          </tr>
        )}
        {productifo.electronics.resolution !== "" && (
          <tr>
            <th>resolution</th>
            <td>{productifo.electronics.resolution}</td>
          </tr>
        )}
        {productifo.electronics.gpu !== "" && (
          <tr>
            <th>gpu</th>
            <td>{productifo.electronics.gpu}</td>
          </tr>
        )}
        {productifo.electronics.display_type !== "" && (
          <tr>
            <th>display type</th>
            <td>{productifo.electronics.display_type}</td>
          </tr>
        )}
        {productifo.electronics.other_display_features !== "" && (
          <tr>
            <th>other display features</th>
            <td>{productifo.electronics.other_display_features}</td>
          </tr>
        )}
        {productifo.electronics.operating_system !== "" && (
          <tr>
            <th>operating system</th>
            <td>{productifo.electronics.operating_system}</td>
          </tr>
        )}
        {productifo.electronics.processor_brand !== "" && (
          <tr>
            <th>processor brand</th>
            <td>{productifo.electronics.processor_brand}</td>
          </tr>
        )}
        {productifo.electronics.processor_type !== "" && (
          <tr>
            <th>processor type</th>
            <td>{productifo.electronics.processor_type}</td>
          </tr>
        )}
        {productifo.electronics.primary_clock_speed !== "" && (
          <tr>
            <th>primary clock speed</th>
            <td>{productifo.electronics.primary_clock_speed}</td>
          </tr>
        )}
        {productifo.electronics.secondary_clock_speed !== "" && (
          <tr>
            <th>secondary clock speed</th>
            <td>{productifo.electronics.secondary_clock_speed}</td>
          </tr>
        )}
        {productifo.electronics.tertiary_clock_speed !== "" && (
          <tr>
            <th>tertiary clock speed</th>
            <td>{productifo.electronics.tertiary_clock_speed}</td>
          </tr>
        )}
        {productifo.electronics.operating_frequency !== "" && (
          <tr>
            <th>operating frequency</th>
            <td>{productifo.electronics.operating_frequency}</td>
          </tr>
        )}
        {productifo.electronics.internal_storage !== "" && (
          <tr>
            <th>internal storage</th>
            <td>{productifo.electronics.internal_storage}</td>
          </tr>
        )}
        {productifo.electronics.ram !== "" && (
          <tr>
            <th>ram</th>
            <td>{productifo.electronics.ram}</td>
          </tr>
        )}
        {productifo.electronics.total_memory !== "" && (
          <tr>
            <th>total memory</th>
            <td>{productifo.electronics.total_memory}</td>
          </tr>
        )}
        {productifo.electronics.call_log_memory !== "" && (
          <tr>
            <th>call log memory</th>
            <td>{productifo.electronics.call_log_memory}</td>
          </tr>
        )}
        {productifo.electronics.primary_camera_available !== "" && (
          <tr>
            <th>primary camera available</th>
            <td>{productifo.electronics.primary_camera_available}</td>
          </tr>
        )}
        {productifo.electronics.primary_camera !== "" && (
          <tr>
            <th>primary camera</th>
            <td>{productifo.electronics.primary_camera}</td>
          </tr>
        )}
        {productifo.electronics.primary_camera_features !== "" && (
          <tr>
            <th>primary camera features</th>
            <td>{productifo.electronics.primary_camera_features}</td>
          </tr>
        )}
        {productifo.electronics.secondary_camera_available !== "" && (
          <tr>
            <th>secondary camera available</th>
            <td>{productifo.electronics.secondary_camera_available}</td>
          </tr>
        )}
        {productifo.electronics.secondary_camera !== "" && (
          <tr>
            <th>secondary camera</th>
            <td>{productifo.electronics.secondary_camera}</td>
          </tr>
        )}
        {productifo.electronics.secondary_camera_features !== "" && (
          <tr>
            <th>secondary camera features</th>
            <td>{productifo.electronics.secondary_camera_features}</td>
          </tr>
        )}
        {productifo.electronics.flash !== "" && (
          <tr>
            <th>flash</th>
            <td>{productifo.electronics.flash}</td>
          </tr>
        )}
        {productifo.electronics.hd_recording !== "" && (
          <tr>
            <th>hd recording</th>
            <td>{productifo.electronics.hd_recording}</td>
          </tr>
        )}
        {productifo.electronics.full_hd_recording !== "" && (
          <tr>
            <th>full hd recording</th>
            <td>{productifo.electronics.full_hd_recording}</td>
          </tr>
        )}
        {productifo.electronics.video_recording !== "" && (
          <tr>
            <th>video recording</th>
            <td>{productifo.electronics.video_recording}</td>
          </tr>
        )}
        {productifo.electronics.video_recording_resolution !== "" && (
          <tr>
            <th>video recording resolution</th>
            <td>{productifo.electronics.video_recording_resolution}</td>
          </tr>
        )}
        {productifo.electronics.digital_zoom !== "" && (
          <tr>
            <th>digital zoom</th>
            <td>{productifo.electronics.digital_zoom}</td>
          </tr>
        )}
        {productifo.electronics.frame_rate !== "" && (
          <tr>
            <th>frame rate</th>
            <td>{productifo.electronics.frame_rate}</td>
          </tr>
        )}
        {productifo.electronics.dual_camera_lens !== "" && (
          <tr>
            <th>dual camera lens</th>
            <td>{productifo.electronics.dual_camera_lens}</td>
          </tr>
        )}
        {productifo.electronics.call_wait_or_hold !== "" && (
          <tr>
            <th>call wait or hold</th>
            <td>{productifo.electronics.call_wait_or_hold}</td>
          </tr>
        )}
        {productifo.electronics.conference_call !== "" && (
          <tr>
            <th>conference call</th>
            <td>{productifo.electronics.conference_call}</td>
          </tr>
        )}
        {productifo.electronics.hands_free !== "" && (
          <tr>
            <th>hands free</th>
            <td>{productifo.electronics.hands_free}</td>
          </tr>
        )}
        {productifo.electronics.call_divert !== "" && (
          <tr>
            <th>call divert</th>
            <td>{productifo.electronics.call_divert}</td>
          </tr>
        )}
        {productifo.electronics.phone_book !== "" && (
          <tr>
            <th>phone book</th>
            <td>{productifo.electronics.phone_book}</td>
          </tr>
        )}
        {productifo.electronics.call_timer !== "" && (
          <tr>
            <th>call timer</th>
            <td>{productifo.electronics.call_timer}</td>
          </tr>
        )}
        {productifo.electronics.speaker_phone !== "" && (
          <tr>
            <th>speaker phone</th>
            <td>{productifo.electronics.speaker_phone}</td>
          </tr>
        )}
        {productifo.electronics.call_records !== "" && (
          <tr>
            <th>call records</th>
            <td>{productifo.electronics.call_records}</td>
          </tr>
        )}
        {productifo.electronics.network_type !== "" && (
          <tr>
            <th>network type</th>
            <td>{productifo.electronics.network_type}</td>
          </tr>
        )}
        {productifo.electronics.supported_networks !== "" && (
          <tr>
            <th>supported networks</th>
            <td>{productifo.electronics.supported_networks}</td>
          </tr>
        )}
        {productifo.electronics.internet_connectivity !== "" && (
          <tr>
            <th>internet connectivity</th>
            <td>{productifo.electronics.internet_connectivity}</td>
          </tr>
        )}
        {productifo.electronics._3g !== "" && (
          <tr>
            <th>_3g</th>
            <td>{productifo.electronics._3g}</td>
          </tr>
        )}
        {productifo.electronics.gprs !== "" && (
          <tr>
            <th>gprs</th>
            <td>{productifo.electronics.gprs}</td>
          </tr>
        )}
        {productifo.electronics.micro_usb_port !== "" && (
          <tr>
            <th>micro usb port</th>
            <td>{productifo.electronics.micro_usb_port}</td>
          </tr>
        )}
        {productifo.electronics.micro_usb_version !== "" && (
          <tr>
            <th>micro usb version</th>
            <td>{productifo.electronics.micro_usb_version}</td>
          </tr>
        )}
        {productifo.electronics.blutooth_support !== "" && (
          <tr>
            <th>blutooth support</th>
            <td>{productifo.electronics.blutooth_support}</td>
          </tr>
        )}
        {productifo.electronics.blutooth_version !== "" && (
          <tr>
            <th>blutooth version</th>
            <td>{productifo.electronics.blutooth_version}</td>
          </tr>
        )}
        {productifo.electronics.wifi !== "" && (
          <tr>
            <th>wifi</th>
            <td>{productifo.electronics.wifi}</td>
          </tr>
        )}
        {productifo.electronics.wifi_version !== "" && (
          <tr>
            <th>wifi version</th>
            <td>{productifo.electronics.wifi_version}</td>
          </tr>
        )}
        {productifo.electronics.wifi_hotspot !== "" && (
          <tr>
            <th>wifi hotspot</th>
            <td>{productifo.electronics.wifi_hotspot}</td>
          </tr>
        )}
        {productifo.electronics.mini_hdmi_port !== "" && (
          <tr>
            <th>mini hdmi port</th>
            <td>{productifo.electronics.mini_hdmi_port}</td>
          </tr>
        )}
        {productifo.electronics.nfc !== "" && (
          <tr>
            <th>nfc</th>
            <td>{productifo.electronics.nfc}</td>
          </tr>
        )}
        {productifo.electronics.usb_tethering !== "" && (
          <tr>
            <th>usb tethering</th>
            <td>{productifo.electronics.usb_tethering}</td>
          </tr>
        )}
        {productifo.electronics.in_the_box !== "" && (
          <tr>
            <th>in_the_box</th>
            <td>{productifo.electronics.in_the_box}</td>
          </tr>
        )}
        {productifo.electronics.in_the_box !== "" && (
          <tr>
            <th>in_the_box</th>
            <td>{productifo.electronics.in_the_box}</td>
          </tr>
        )}
      </tbody>
    </>
  );
};

export default Electronic_Supercategory;
