import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Autoplay, FreeMode, Mousewheel, Pagination } from "swiper/modules";

import { Avatar, Card } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
const { Meta } = Card;

const ExploreOur = ({ products }) => {
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    const sortedProducts = products.sort((a, b) => {
      return (
        b.packSize[0].PackSizeOptions[0].Discount -
        a.packSize[0].PackSizeOptions[0].Discount
      );
    });
    setProduct(sortedProducts);
  }, [products]);

  return (
    <>
      <div>
        {product.length > 0 ? (
          <>
            <div className="offernyka">
              <div className="nykacard for_offerProducts">
                <div className="heading" style={{marginTop:"60px"}}>
                  <h2 style={{fontFamily:"Edu VIC WA NT Beginner,cursive"}}>OFFER PRODUCT</h2>
                </div>
                <Swiper
                  slidesPerView={5}
                  spaceBetween={20}
                  freeMode={true}
                  // pagination={{
                  //   clickable: false,
                  // }}
                  modules={[Pagination, Mousewheel, Autoplay]}
                  loop={true}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  breakpoints={{
                    360: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    460: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    720: {
                      slidesPerView: 3,
                      spaceBetween: 15,
                    },
                    1080: {
                      slidesPerView: 6,
                      spaceBetween: 15,
                    },
                  }}
                  className="mySwiper"
                >
                  {product
                    .slice(0, 24)
                    .reverse()
                    .map((indPro, index) => (
                      <SwiperSlide
                        onClick={() =>
                          navigate(`/product-info/${indPro.slugUrl}`)
                        }
                      >
                        <div className="card-parents">
                          <div
                            className="card"
                            style={{
                              backgroundImage: `url(${indPro.packSize[0].thumbnail})`,
                              aspectRatio: "3 / 2",
                              objectFit:"contain"
                            }}
                          >
                            <span id="lorel">
                              {/* {indPro.brand.length > 8
                                ? `${indPro.brand.slice(0, 6)}...`
                                : indPro.brand} */}
                              {/* <br /> */}
                              {100 -
                                indPro.packSize[0].PackSizeOptions[0].Discount}
                              % off
                            </span>
                          </div>

                          <div id="cardtext" className="">
                            <p className="">
                              <br />
                              <span className="text-secondary">
                                {indPro.name.length > 16
                                  ? `${indPro.name.slice(0, 16)}...`
                                  : indPro.name}
                              </span>
                            </p>
                            <Link to={`/product-info/${indPro.slugUrl}`}>
                              <button
                                style={{
                                  color: "#FD357D",
                                  backgroundColor: "#fff",
                                  borderRadius: "8px",
                                  padding: "2px",
                                  fontWeight: "bold",
                                  height: "30px",
                                  fontSize: "10px",
                                  width:"68px"
                                }}
                              >
                                Shop Now{" "}
                                <span>
                                  <FaArrowRight />
                                </span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ExploreOur;
