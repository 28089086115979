import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ElectronicSubCategory = () => {
  const { electronic_subcategory } = useSelector(
    (store) => store.subCategories
  );

  const navigate = useNavigate();

  return (
    <>
      {electronic_subcategory.length > 0 && (
        <>
          <div className="section home-instagram">
            <div className="container">
              <div className="section-header">
                {/* <p className="mb-2 mt-0">Shop by category</p> */}
                <h2 className="TitleHeading">Popular Collections in Electronics</h2>
              </div>

              {/*Instagram grid*/}
              <div className="instagram-section instagram-grid">
                <div className="row sp-row row-cols-xl-5 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2">
                 
                 
                  {electronic_subcategory &&
                    electronic_subcategory.map((showData, index) => (
                      <div className="category-item zoomscal-hov" style={{marginTop:"-5px"}} key={index} >
                        <Link
                          to={`/shop/${showData.slugUrl}`}
                          className="category-link clr-none"
                        >
                          <div className="zoom-scal zoom-scal-nopb rounded-3">
                            <img
                              className="blur-up lazyload"
                              src={showData.desktopImage}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "/assets/images/collection/sub-collection3.jpg";
                              }}
                              alt="Tops"
                              title="Tops"
                              width={365}
                              height={365}
                            />
                          </div>
                          <div className="details mt-3 text-center detailsSwipper">
                            <h4 className="category-title mb-0">
                              {showData.name}
                            </h4>
                            {/* <p className="counts">
                            {showData.product_Length} Products
                          </p> */}
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ElectronicSubCategory;
