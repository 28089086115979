import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearCart, getCartTotal } from "../../redux/cart/CartSlice";
import moment from "moment/moment";

const OrderSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentOrder } = useSelector((state) => state.Order);
  const { all_amount_data } = useSelector((state) => state.cart);

  useEffect(() => {
    if (currentOrder.length === 0) {
      navigate("/");
    }

    dispatch(clearCart());
    dispatch(getCartTotal());
  }, [currentOrder]);

  return (
    <>
      {currentOrder.length > 0 && (
        <div id="page-content">
          {/* <div className="page-header text-center">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                <div className="page-title">
                  <h1>Order success</h1>
                </div>
                <div className="breadcrumbs">
                  <Link to="/" title="Back to the home page">
                    Home
                  </Link>
                  <span className="main-title">
                    <i className="icon anm anm-angle-right-l" />
                    Order success
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          <div className="container" style={{marginTop:"10px"}}>
            <div className="success-text checkout-card text-center mb-4 mb-md-5">
              <i className="icon anm anm-shield-check" />
              <h2>Thank you for your order!</h2>
              <p className="mb-1">
                Payment is successfully processsed and your order is on the way
              </p>
              <p className="mb-1">
                You will receive an order confirmation email with details of
                your order and a link to track its progress.
              </p>
              <p
                className="text-order badge bg-success mt-3 cursor_pointer"
                onClick={() => navigate("/")}
              >
                Continue Shopping
              </p>
            </div>
            <div className="row checkout-form">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <div className="block order-summary">
                  <div className="block-content">
                    <h3 className="title mb-3">Order Summary</h3>
                    <div className="table-responsive-sm table-bottom-brd order-table">
                      <table className="table table-hover align-middle mb-0">
                        <thead>
                          <tr>
                            <th className="text-start">Image</th>
                            <th className="text-start proName">Product</th>
                            <th className="text-center">Qty</th>
                            <th className="text-center">Price</th>
                            <th className="text-center">Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentOrder &&
                            currentOrder.map((item, index) => (
                              <tr key={index}>
                                <td className="text-start">
                                  <span className="thumb">
                                    <img
                                      className="rounded-0 blur-up lazyload"
                                      data-src={item.thumbnail}
                                      src={item.thumbnail}
                                      alt={item.packSizeOptionName}
                                      title="product"
                                      width={120}
                                      height={170}
                                    />
                                  </span>
                                </td>
                                <td className="text-start proName">
                                  <div className="list-view-item-title limited-paragraph">
                                    {item.ProductName}
                                  </div>
                                  <div className="cart-meta-text ">
                                    Pack size: {item.packSizeOptionName}
                                    <br />
                                    Size: {item.packSizeOptionName}
                                    <br />
                                    Qty: {item.cart_Quentity}
                                  </div>
                                </td>
                                <td className="text-center">
                                  {item.cart_Quentity}
                                </td>
                                <td className="text-center">₹{item.Price}</td>
                                <td className="text-center">
                                  <strong>₹{item.Product_total_Price}</strong>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="cart-info mt-4 mb-4 mb-lg-0">
                  <div className="cart-order-detail cart-col">
                    <div className="row g-0 border-bottom pb-2">
                      <span className="col-6 col-sm-6 cart-subtotal-title">
                        <strong>Subtotal</strong>
                      </span>
                      <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                        <span className="money">
                          {" "}
                          ₹{all_amount_data.TotalPrice}
                        </span>
                      </span>
                    </div>
                    <div className="row g-0 border-bottom py-2">
                      <span className="col-6 col-sm-6 cart-subtotal-title">
                        <strong>Coupon Discount</strong>
                      </span>
                      <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                        <span className="money">
                          - ₹{all_amount_data.coupon}
                        </span>
                      </span>
                    </div>

                    <div className="row g-0 border-bottom py-2">
                      <span className="col-6 col-sm-6 cart-subtotal-title">
                        <strong>Handlling Fee</strong>
                      </span>
                      <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                        <span className="money">
                          {" "}
                          ₹{all_amount_data.HandllingFee}
                        </span>
                      </span>
                    </div>
                    {all_amount_data.rainStatus === true && (
                      <div className="row g-0 border-bottom py-2">
                        <span className="col-6 col-sm-6 cart-subtotal-title">
                          <strong>Rain Fee</strong>
                        </span>
                        <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                          <span className="money">
                            {" "}
                            ₹{all_amount_data.RainFee}
                          </span>
                        </span>
                      </div>
                    )}

                    <div className="row g-0 border-bottom py-2">
                      <span className="col-6 col-sm-6 cart-subtotal-title">
                        <strong>Shipping</strong>
                      </span>
                      <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                        {all_amount_data.DeliveryCharge === 0 ? (
                          <>
                            <span className="money">Free shipping</span>
                          </>
                        ) : (
                          <>
                            <span className="money">
                              {all_amount_data.DeliveryCharge}
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                    <div className="row g-0 pt-2">
                      <span className="col-6 col-sm-6 cart-subtotal-title fs-6">
                        <strong>Netpayable</strong>
                      </span>
                      <span className="col-6 col-sm-6 cart-subtotal-title fs-5 cart-subtotal text-end text-primary">
                        <b className="money"> ₹{all_amount_data.Netpayable}</b>
                      </span>
                    </div>
                    {/* <p className="cart-shipping mt-3">
                    Shipping &amp; taxes calculated at checkout
                  </p> */}
                    {all_amount_data.DeliveryCharge === 0 && (
                      <p className="cart-shipping fst-normal freeShipclaim">
                        <i className="me-2 align-middle icon anm anm-truck-l" />
                        <b>FREE SHIPPING</b> ELIGIBLE
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <div className="block">
                  <div className="block-content">
                    <div className="row g-0">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="shipping-details mb-4 mb-sm-0">
                          <h3 className="title mb-3">Shipping Address</h3>
                          <p>{currentOrder[0].Address.slice(0, 50)}</p>
                          <p>{currentOrder[0].Address.slice(50, 100)}</p>
                          <p>{currentOrder[0].Address.slice(100, 150)}</p>
                        </div>
                      </div>
                      {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div className="billing-details">
                        <h3 className="title mb-3">Billing Address</h3>
                        <p>No 40 Gallaxy Enque Street 133/2,</p>
                        <p>New York,</p>
                        <p>USA</p>
                        <p>00004-1988</p>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="block mt-4">
                  <div className="block-content">
                    <div className="row g-0">
                      {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div className="shipping-details mb-4 mb-sm-0">
                        <h3 className="title mb-3">Shipping Method</h3>
                        <p>Flat Rate - Fixeds</p>
                        <p>Delivery Date: N/A</p>
                      </div>
                    </div> */}
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="billing-details">
                          <h3 className="title mb-3">Payment Method</h3>
                          <p>{currentOrder[0].PaymentStatus}</p>
                          <p>{currentOrder[0].PaymentMode}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block mt-4">
                  <div className="block-content">
                    <div className="row g-0">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="shipping-details mb-4 mb-sm-0">
                          <h3 className="title mb-3">Order details</h3>
                          {/* <p>Order ID: GHERT05764</p> */}
                          <p>
                            Order Date:{" "}
                            {moment(currentOrder[0].createdAt).format(
                              "MMMM Do, YYYY"
                            )}
                          </p>
                          <p style={{color:"#000",fontSize:'15px',fontWeight:"700"}}>Order Total: ₹{all_amount_data.Netpayable}</p>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="billing-details">
                          <h3 className="title mb-3">
                            Expected date of delivery
                          </h3>
                          <p>Your order is on the way</p>
                          <p className="h5 my-2">
                            {moment(currentOrder[0].ExpectedDelDate).format(
                              "MMMM Do, YYYY"
                            )}
                          </p>
                          {/* <p>
                          <Link to="#" className="btn-link">
                            Track order
                          </Link>
                        </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex-wrap w-100 mt-4 text-center">
                  <Link
                    to="/"
                    className="d-inline-flex align-items-center btn btn-outline-primary me-2 mb-2 me-sm-3"
                  >
                    <i className="me-2 icon an an-angle-left-r" />
                    Continue Shopping
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div style={{marginBottom:"50px"}}></div>
        </div>
      )}
    </>
  );
};

export default OrderSuccess;
