import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCartTotal, set_all_amount_data } from "../../redux/cart/CartSlice";
import { setcurrentOrder } from "../../redux/order/OrderSlice";
import { FiPhone } from "react-icons/fi";
import {
  set_checkout_authentication_status,
  signout,
} from "../../redux/athentication/Athentication";
import { SiSellfy } from "react-icons/si";
import { TiShoppingCart } from "react-icons/ti";
import { FaSearch } from "react-icons/fa";
import { FaShoppingBag } from "react-icons/fa";

import { Affix } from "antd";
import { IoArrowUndo } from "react-icons/io5";
import { RiShoppingBag2Line } from "react-icons/ri";
import { logRoles } from "@testing-library/react";

import { Drawer, Radio, Space } from "antd";
import { IoIosClose } from "react-icons/io";
import { LiaSellcast } from "react-icons/lia";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "react-bootstrap";
import { CiHeart, CiSearch } from "react-icons/ci";
import { MdOutlineShoppingBag } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const Hadear = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const { mainHomeSliderTotal } = useSelector((store) => store.slider);

  const [hovercontent, setHovercontent] = useState(false);

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
  } = useSelector((state) => state.cart);

  const { isAuth } = useSelector((state) => state.Athentication);

  const { categoryShow } = useSelector((store) => store.category);
  const { categoryTagShow } = useSelector((store) => store.categorytag);
  const { subcategoryShow } = useSelector((store) => store.subCategories);

  const [search, setSearch] = useState("");
  const [subcatSearchResult, setSubcatSearchResult] = useState([]);
  const [categoryTagSearchResult, setCategoryTagSearchResult] = useState([]);
  const [categorySearchResult, setCategorySearchResult] = useState([]);
  const [ProductSearchResult, setProductSearchResult] = useState([]);

  const [showResults, setShowResults] = useState(false);
  const [showSeller, setShowSeller] = useState(false);

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems]);

  const searchchange = (e) => {
    const result = e.target.value.replace(/^\s+/, "");
    setSearch(result);

    if (result.length >= 2) {
      const filter_category = categoryShow
        .filter((category) =>
          category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_sub_category = subcategoryShow
        .filter((sub_category) =>
          sub_category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_category_tag = categoryTagShow
        .filter((category_tag) =>
          category_tag.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);

      setCategorySearchResult(filter_category);
      setSubcatSearchResult(filter_sub_category);
      setCategoryTagSearchResult(filter_category_tag);
      setShowResults(true);
    }
  };
  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const searchsubmit = (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
      setSearch("");
    } else {
      alert("Please type something...");
    }
  };

  const serchclick = () => {
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };
  const catSearchClick = (category) => {
    if (search !== "") {
      navigate(`/search/${category.superCategory}/${category.slugUrl}`);
      setShowResults(false);
      setSearch("");
    }
  };

  const signoutClick = () => {
    dispatch(signout());
    navigate("/");
  };
  // console.log(categorySearchResult, "categorySearchResult");
  // console.log(subcatSearchResult, "subcatSearchResult");
  // console.log(categoryTagSearchResult, "categoryTagSearchResult");

  return (
    <>
      <div>
        {/* <div className="topbar-slider clearfix">
          <div className="container-fluid">
            <div className="marquee-text">
              <div className="top-info-bar d-flex">
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" />
                      info@ewshopping.com
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> FREE SHIPPING ON ALL
                      ORDERS ABOVE ₹500
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> Sell on EWShopping
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-redo-ar" /> EXTENDED RETURN UNTIL 30
                      DAYS
                    </span>
                  </Link>
                </div>
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-worldwide" /> BUY ONLINE BEST DEALS
                      ON ELECTRONICS
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> FREE SHIPPING ON ALL
                      ORDERS ABOVE ₹500
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-redo-ar" /> EXTENDED RETURN UNTIL 30
                      DAYS
                    </span>
                  </Link>
                </div>
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-worldwide" /> BUY ONLINE STYLISH
                      OUTFITS & LATEST TRENDS
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> FREE SHIPPING ON ALL
                      ORDERS ABOVE ₹500
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-redo-ar" /> EXTENDED RETURN UNTIL 30
                      DAYS
                    </span>
                  </Link>
                </div>
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-worldwide" /> BUY ONLINE FASHIONABLE
                      APPAREL
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="top-header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-center d-none d-md-block">
                Free shipping on all orders over ₹500{" "}
                <Link to="/" className="text-link ms-1">
                  Shop now
                </Link>
              </div>
          
            </div>
          </div>
        </div> */}
        <Affix offsetTop={0}>
          <div>
            {/* <header
              className="header   header-1 header-fixed desktopVersion"
              style={{ background: "#2f415d" }}
            >
              <div className="container  " style={{ paddingTop: "15px" }}>
                <div className="row">
                  <div className="logo col-5 col-sm-3 col-md-2 col-lg-3 align-self-center is-fixed desktopVersion">
                    <Link className="logoImg" to="/">
                      <img
                        src="/assets/images/logonew14.png"
                        alt="EwShopping"
                        title="EwShopping"
                        className="imglogo"
                      />
                    </Link>
                  </div>

                  <div className="col-1 col-sm-6 col-md-1 col-lg-6 align-self-center d-menu-col ">
                    <nav className="navigation " id="AccessibleNav">
                      <div
                        className="search-body "
                        style={{
                          border: "1.5px solid #2f415d",
                          borderRadius: "5px",
                        }}
                        ref={ref}
                      >
                        <form
                          className="form minisearch"
                          id="header-search"
                          onSubmit={(e) => searchsubmit(e)}
                        >
                          <div
                            className="d-flex searchField"
                            style={{ position: "relative" }}
                          >
                            <div className="input-box d-flex fl-1">
                              <input
                                type="text"
                                className="input-text border-start-0 border-end-0"
                                placeholder="Search for products..."
                                value={search}
                                onChange={searchchange}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              <button
                                type="submit"
                                onClick={serchclick}
                                className="action search d-flex-justify-center btn rounded-start-0"
                              >
                                <span style={{ color: "white" }}>
                                  {" "}
                                  <i className="icon anm anm-search-l" />
                                </span>
                              </button>
                            </div>
                          </div>
                          {showResults && (
                            <>
                              <div className="serchsugist">
                                {categorySearchResult.length > 0 && (
                                  <ul className="">
                                    {categorySearchResult &&
                                      categorySearchResult.map(
                                        (category, index) => (
                                          <li className="item" key={index}>
                                            <div
                                              className="mini-list-item d-flex align-items-center w-100 clearfix"
                                              onClick={() =>
                                                catSearchClick(category)
                                              }
                                            >
                                              <div className="mini-image text-center">
                                                <Link className="item-link">
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category.desktopImage
                                                    }
                                                    src={category.desktopImage}
                                                    alt={category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link className="item-title">
                                                    {category.name}
                                                  </Link>
                                                </div>

                                                <Link>
                                                  <div className="product-review d-flex align-items-center justify-content-start">
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                        color: "#2f415d",
                                                      }}
                                                    >
                                                      in{" "}
                                                      {category.superCategory}
                                                    </span>
                                                  </div>
                                                </Link>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {subcatSearchResult.length > 0 && (
                                  <ul>
                                    {subcatSearchResult &&
                                      subcatSearchResult.map(
                                        (sub_category, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                                  onClick={() =>
                                                    setShowResults(false)
                                                  }
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      sub_category.desktopImage
                                                    }
                                                    src={
                                                      sub_category.desktopImage
                                                    }
                                                    alt={sub_category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                                    onClick={() =>
                                                      setShowResults(false)
                                                    }
                                                  >
                                                    {sub_category.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in{" "}
                                                    {sub_category.superCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {categoryTagSearchResult.length > 0 && (
                                  <ul>
                                    {categoryTagSearchResult &&
                                      categoryTagSearchResult.map(
                                        (category_tag, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/tag-search/${category_tag.slugUrl}`}
                                                  onClick={() =>
                                                    setShowResults(false)
                                                  }
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category_tag.desktopImage
                                                    }
                                                    src={
                                                      category_tag.desktopImage
                                                    }
                                                    alt={category_tag.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/tag-search/${category_tag.slugUrl}`}
                                                    onClick={() =>
                                                      setShowResults(false)
                                                    }
                                                  >
                                                    {category_tag.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in{" "}
                                                    {category_tag.SubCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                              </div>
                            </>
                          )}
                        </form>
                      </div>
                    </nav>
                  </div>

                  <div className="col-7 col-sm-3 col-md-9 col-lg-3 align-self-center icons-col text-right desktopVersion">
                    <div className="search-parent iconset ">
                      <div className="site-search mobileVersion" title="Search">
                        <Link
                          to=""
                          className="search-icon clr-none"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#search-drawer"
                        >
                          <i className="hdr-icon icon anm anm-search-l" />
                        </Link>
                      </div>

                      <div
                        className="search-drawer offcanvas offcanvas-top"
                        tabIndex={-1}
                        id="search-drawer"
                      >
                        <div
                          className="container"
                          style={{ zIndex: "9999 !important" }}
                        >
                          <div className="search-header d-flex-center justify-content-between mb-3">
                            <h3 className="title m-0">
                              What are you looking for?
                            </h3>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            />
                          </div>
                          <div className="search-body">
                            <form
                              className="form minisearch"
                              id="header-search"
                              onSubmit={(e) => searchsubmit(e)}
                            >
                              <div className="d-flex searchField">
                                <div className="input-box d-flex fl-1">
                                  <input
                                    type="text"
                                    className="input-text border-start-0 border-end-0"
                                    placeholder="Search for products..."
                                    value={search}
                                    onChange={searchchange}
                                  />
                                  <button
                                    type="submit"
                                    onClick={serchclick}
                                    className="action search d-flex-justify-center btn rounded-start-0"
                                  >
                                    <span style={{ color: "white" }}>
                                      {" "}
                                      <i className="icon anm anm-search-l" />
                                    </span>
                                  </button>
                                </div>
                              </div>

                              <div className="search-products">
                                {subcatSearchResult.length === 0 &&
                                  categorySearchResult.length === 0 &&
                                  categoryTagSearchResult.length === 0 && (
                                    <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                      <li className="item empty w-100 text-center text-muted d-block">
                                        You don't have any items in your search.
                                      </li>
                                    </ul>
                                  )}

                                {categorySearchResult.length > 0 && (
                                  <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                    {categorySearchResult &&
                                      categorySearchResult.map(
                                        (category, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category.desktopImage
                                                    }
                                                    src={category.desktopImage}
                                                    alt={category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                  >
                                                    {category.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in {category.superCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {subcatSearchResult.length > 0 && (
                                  <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                    {subcatSearchResult &&
                                      subcatSearchResult.map(
                                        (sub_category, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      sub_category.desktopImage
                                                    }
                                                    src={
                                                      sub_category.desktopImage
                                                    }
                                                    alt={sub_category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                                  >
                                                    {sub_category.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in {sub_category.category}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {categoryTagSearchResult.length > 0 && (
                                  <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                    {categoryTagSearchResult &&
                                      categoryTagSearchResult.map(
                                        (category_tag, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/tag-search/${category_tag.slugUrl}`}
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category_tag.desktopImage
                                                    }
                                                    src={
                                                      category_tag.desktopImage
                                                    }
                                                    alt={category_tag.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/tag-search/${category_tag.slugUrl}`}
                                                  >
                                                    {category_tag.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in{" "}
                                                    {category_tag.SubCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="account-parent iconset">
                      <div className="account-link" title="Account">
                        <i className="hdr-icon icon anm anm-user-al" />
                      </div>
                      <div id="accountBox">
                        <div className="customer-links">
                          <ul className="m-0">
                            {isAuth === true ? (
                              <>
                                <li>
                                  <Link to="/my-account">
                                    <i className="icon anm anm-user-cil" />
                                    My Account
                                  </Link>
                                </li>
                                <li onClick={() => signoutClick()}>
                                  <i className="icon anm anm-sign-out-al" />
                                  Sign out
                                </li>
                              </>
                            ) : (
                              <>
                                <li
                                  onClick={() => {
                                    dispatch(
                                      set_checkout_authentication_status(0)
                                    );
                                    navigate("/login");
                                  }}
                                >
                                  <Link to="/login">
                                    <i className="icon anm anm-user-cil" />
                                    Sign In
                                  </Link>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="header-cart iconset" title="Cart">
                      <Link
                        className="header-cart btn-minicart clr-none"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#minicart-drawer"
                      >
                        <i className="hdr-icon icon anm anm-cart-l" />
                        <span className="cart-count">{CartItems.length}</span>
                      </Link>

                      <div
                        id="minicart-drawer"
                        className="minicart-right-drawer offcanvas offcanvas-end"
                        tabIndex={-1}
                      >
                        {CartItems.length === 0 ? (
                          <>
                            <div
                              id="cartEmpty"
                              className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                            >
                              <div className="minicart-header d-flex-center justify-content-between w-100">
                                <h4 className="fs-6">
                                  Your cart ({CartItems.length} Items)
                                </h4>
                                <button
                                  className="close-cart border-0"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  <i
                                    className="icon anm anm-times-r"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Close"
                                  />
                                </button>
                              </div>
                              <div className="cartEmpty-content mt-4">
                                <i className="icon anm anm-cart-l fs-1 text-muted" />
                                <p className="my-3">No Products in the Cart</p>
                                <span
                                  className="btn btn-primary cart-btn"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                  onClick={() => navigate("/")}
                                >
                                  Continue shopping
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div id="cart-drawer" className="block block-cart">
                              <div className="minicart-header">
                                <button
                                  className="close-cart border-0"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  <i
                                    className="icon anm anm-times-r"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Close"
                                  />
                                </button>
                                <h4 className="fs-6">
                                  Your cart ({CartItems.length} Items)
                                </h4>
                              </div>
                              <div className="minicart-content">
                                <ul className="m-0 clearfix">
                                  {CartItems &&
                                    CartItems.map((item, index) => (
                                      <li
                                        className="item d-flex justify-content-center align-items-center"
                                        key={index}
                                      >
                                        <span className="product-image rounded-3">
                                          <img
                                            className="blur-up lazyload"
                                            data-src={item.thumbnail}
                                            src={item.thumbnail}
                                            alt="product"
                                            title="Product"
                                            width={120}
                                            height={170}
                                          />
                                        </span>
                                        <div className="product-details">
                                          <span
                                            className="product-title limited-paragraph"
                                            onClick={() => {
                                              if ("slugurl" in item) {
                                                navigate(
                                                  `/product-info/${item.slugurl}`
                                                );
                                              }
                                            }}
                                            data-bs-dismiss="offcanvas"
                                            aria-label="Close"
                                          >
                                            {item.ProductName}
                                          </span>
                                          <div className="variant-cart my-2 limited-paragraph">
                                            {item.packSizeOptionName} /{" "}
                                            {item.packSizeName}
                                          </div>
                                          <div className="priceRow">
                                            <div className="product-price">
                                              <span className="price">
                                                {" "}
                                                ₹{item.Price}
                                              </span>{" "}
                                              x{" "}
                                              <span className="price">
                                                {" "}
                                                {item.cart_Quentity}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              <div className="minicart-bottom">
                                <div className="shipinfo">
                                  {amountToGetfeeDelivery !== 0 && (
                                    <div className="progress mb-2">
                                      <div
                                        className="progress-bar progress-bar-striped progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow={70}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{
                                          width: `${amountToGetfeeDeliveryPercentage}%`,
                                        }}
                                      >
                                        {amountToGetfeeDeliveryPercentage}%
                                      </div>
                                    </div>
                                  )}

                                  {amountToGetfeeDeliveryPercentage === 0 ? (
                                    <>
                                      <div className="freeShipMsg">
                                        <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                        Only{" "}
                                        <span
                                          className="money"
                                          data-currency-usd=" ₹199.00"
                                          data-currency="USD"
                                        >
                                          {" "}
                                          ₹{amountToGetfeeDelivery}
                                        </span>{" "}
                                        away from
                                        <b>Free Shipping</b>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="freeShipMsg d-block">
                                        <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                        Congrats! You are eligible for
                                        <b>Free Shipping</b>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="subtotal clearfix my-3">
                                  <div className="totalInfo clearfix mb-1 d-block">
                                    <span>Total:</span>
                                    <span className="item product-price">
                                      {" "}
                                      ₹{TotalPrice}
                                    </span>
                                  </div>
                                  <div className="totalInfo clearfix mb-1 d-block">
                                    <span>Handlling Fee:</span>
                                    <span className="item product-price">
                                      {" "}
                                      ₹{HandllingFee}
                                    </span>
                                  </div>
                                  <div className="totalInfo clearfix">
                                    <span>Netpayable:</span>
                                    <span className="item product-price">
                                      {" "}
                                      ₹{TotalAmount}
                                    </span>
                                  </div>
                                </div>

                                <div className="minicart-action d-flex mt-3">
                                  <span
                                    onClick={() => navigate("/check-out")}
                                    className="proceed-to-checkout btn btn-primary w-50 me-1"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    Check Out
                                  </span>
                                  <span
                                    onClick={() => navigate("/cart")}
                                    className="cart-btn btn btn-secondary w-50 ms-1"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    View Cart
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="header-cart iconset" title="Cart">
                      <Link
                        className="header-cart btn-minicart clr-none"
                        to="https://seller.ewshopping.com"
                        target="_blank"
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            fontWeight: "600",
                          }}
                        >
                          Sell On Ewshopping
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </header> */}

            {/* <header
              className="header align-items-center header-1 header-fixed mobileVersion mobileversionwidth"
              style={{ background: "#2f415d" }}
            >
              <div
                className="container  mobile_version_container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="row mobile_row_version">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#2f415d",
                    }}
                    className="container_input"
                  >
                    <div className="col-9 col-sm-1 col-md-1 col-lg-8 align-self-center d-menu-col mobileVersion search-bar">
                      <nav
                        className="navigation mobile_navigation"
                        id="AccessibleNav"
                      >
                        <div className="search-body" ref={ref}>
                          <form
                            className="form minisearch"
                            id="header-search"
                            onSubmit={(e) => searchsubmit(e)}
                          >
                            <div
                              className="d-flex searchField inputfiledsize"
                              style={{
                                position: "relative",
                                borderRadius: "6px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <div
                                className="input-box d-flex fl-1 mobile_input"
                                id="inputs_filed_mobile"
                              >
                                <input
                                  type="text"
                                  className="input-text border-start-0 border-end-0 mobile1_input"
                                  placeholder="Search for products..."
                                  value={search}
                                  onChange={searchchange}
                                  style={{
                                    border: "none",
                                  }}
                                />
                                <button
                                  type="submit"
                                  onClick={serchclick}
                                  className="action search d-flex-justify-center "
                                  style={{
                                    backgroundColor: "#febd69",

                                    height: "40px",
                                    width: "50px",
                                  }}
                                >
                                  <span
                                    style={{ color: "#fff", fontWeight: "800" }}
                                  >
                                    {" "}
                                    <FaSearch style={{ fontSize: "18px" }} />
                                  </span>
                                </button>
                              </div>
                            </div>
                            {showResults && (
                              <>
                                <div
                                  className="serchsugist"
                                  style={{ width: "300px" }}
                                >
                                  {categorySearchResult.length > 0 && (
                                    <ul className="">
                                      {categorySearchResult &&
                                        categorySearchResult.map(
                                          (category, index) => (
                                            <li className="item" key={index}>
                                              <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                                <div className="mini-image text-center">
                                                  <Link
                                                    className="item-link"
                                                    to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                    onClick={() =>
                                                      setShowResults(false)
                                                    }
                                                  >
                                                    <img
                                                      className="blur-up lazyload"
                                                      data-src={
                                                        category.desktopImage
                                                      }
                                                      src={
                                                        category.desktopImage
                                                      }
                                                      alt={category.name}
                                                      title="product"
                                                      width={120}
                                                      height={170}
                                                    />
                                                  </Link>
                                                </div>
                                                <div className="ms-3 details text-left">
                                                  <div className="product-name">
                                                    <Link
                                                      className="item-title"
                                                      to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                      onClick={() =>
                                                        setShowResults(false)
                                                      }
                                                    >
                                                      {category.name}
                                                    </Link>
                                                  </div>

                                                  <div className="product-review d-flex align-items-center justify-content-start">
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                        color: "#2f415d",
                                                      }}
                                                    >
                                                      in{" "}
                                                      {category.superCategory}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  )}
                                  {subcatSearchResult.length > 0 && (
                                    <ul>
                                      {subcatSearchResult &&
                                        subcatSearchResult.map(
                                          (sub_category, index) => (
                                            <li className="item" key={index}>
                                              <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                                <div className="mini-image text-center">
                                                  <Link
                                                    className="item-link"
                                                    to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                                    onClick={() =>
                                                      setShowResults(false)
                                                    }
                                                  >
                                                    <img
                                                      className="blur-up lazyload"
                                                      data-src={
                                                        sub_category.desktopImage
                                                      }
                                                      src={
                                                        sub_category.desktopImage
                                                      }
                                                      alt={sub_category.name}
                                                      title="product"
                                                      width={120}
                                                      height={170}
                                                    />
                                                  </Link>
                                                </div>
                                                <div className="ms-3 details text-left">
                                                  <div className="product-name">
                                                    <Link
                                                      className="item-title"
                                                      to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                                      onClick={() =>
                                                        setShowResults(false)
                                                      }
                                                    >
                                                      {sub_category.name}
                                                    </Link>
                                                  </div>

                                                  <div className="product-review d-flex align-items-center justify-content-start">
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                        color: "#2f415d",
                                                      }}
                                                    >
                                                      in{" "}
                                                      {
                                                        sub_category.superCategory
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  )}
                                  {categoryTagSearchResult.length > 0 && (
                                    <ul>
                                      {categoryTagSearchResult &&
                                        categoryTagSearchResult.map(
                                          (category_tag, index) => (
                                            <li className="item" key={index}>
                                              <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                                <div className="mini-image text-center">
                                                  <Link
                                                    className="item-link"
                                                    to={`/tag-search/${category_tag.slugUrl}`}
                                                    onClick={() =>
                                                      setShowResults(false)
                                                    }
                                                  >
                                                    <img
                                                      className="blur-up lazyload"
                                                      data-src={
                                                        category_tag.desktopImage
                                                      }
                                                      src={
                                                        category_tag.desktopImage
                                                      }
                                                      alt={category_tag.name}
                                                      title="product"
                                                      width={120}
                                                      height={170}
                                                    />
                                                  </Link>
                                                </div>
                                                <div className="ms-3 details text-left">
                                                  <div className="product-name">
                                                    <Link
                                                      className="item-title"
                                                      to={`/tag-search/${category_tag.slugUrl}`}
                                                      onClick={() =>
                                                        setShowResults(false)
                                                      }
                                                    >
                                                      {category_tag.name}
                                                    </Link>
                                                  </div>

                                                  <div className="product-review d-flex align-items-center justify-content-start">
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                        color: "#2f415d",
                                                      }}
                                                    >
                                                      in{" "}
                                                      {category_tag.SubCategory}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  )}
                                </div>
                              </>
                            )}
                          </form>
                        </div>
                      </nav>
                    </div>
                    <div
                      className="logo col-2 col-sm-3 col-md-3 col-lg-2 align-self-center is-fixed mobileVersion seller_icon"
                      style={{ marginLeft: "30px" }}
                    >
                      <Link
                        className=""
                        to="https://seller.ewshopping.com"
                        target="_blank"
                      >
                        <div
                          style={{
                            height: "38px",
                            width: "38px",
                            backgroundColor: "#febd69",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "10px",
                          }}
                        >
                          <FaShoppingBag style={{ fontSize: "24px" }} />
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="col-1 col-sm-9 col-md-9 col-lg-2 align-self-center icons-col text-right mobileVersion mobileVersionaccount">
                    <div className="search-parent iconset ">
                      <div className="site-search mobileVersion" title="Search">
                        <Link
                          to=""
                          className="search-icon clr-none"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#search-drawer"
                        >
                          <i className="hdr-icon icon anm anm-search-l" />
                        </Link>
                      </div>

                      <div
                        className="search-drawer offcanvas offcanvas-top"
                        tabIndex={-1}
                        id="search-drawer"
                      >
                        <div
                          className="container"
                          style={{ zIndex: "9999 !important" }}
                        >
                          <div className="search-header d-flex-center justify-content-between mb-3">
                            <h3 className="title m-0">
                              What are you looking for?
                            </h3>
                            <button
                              type="button"
                              className="btn-close text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            />
                          </div>
                          <div className="search-body ">
                            <form
                              className="form minisearch"
                              id="header-search"
                              onSubmit={(e) => searchsubmit(e)}
                            >
                              <div className="d-flex searchField">
                                <div className="input-box d-flex fl-1">
                                  <input
                                    type="text"
                                    className="input-text border-start-0 border-end-0"
                                    placeholder="Search for products..."
                                    value={search}
                                    onChange={searchchange}
                                  />
                                  <button
                                    type="submit"
                                    onClick={serchclick}
                                    className="action search d-flex-justify-center btn rounded-start-0"
                                  >
                                    <span style={{ color: "white" }}>
                                      {" "}
                                      <i className="icon anm anm-search-l" />
                                    </span>
                                  </button>
                                </div>
                              </div>

                              <div className="search-products">
                                {subcatSearchResult.length === 0 &&
                                  categorySearchResult.length === 0 &&
                                  categoryTagSearchResult.length === 0 && (
                                    <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                      <li className="item empty w-100 text-center text-muted d-block">
                                        You don't have any items in your search.
                                      </li>
                                    </ul>
                                  )}

                                {categorySearchResult.length > 0 && (
                                  <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                    {categorySearchResult &&
                                      categorySearchResult.map(
                                        (category, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category.desktopImage
                                                    }
                                                    src={category.desktopImage}
                                                    alt={category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                  >
                                                    {category.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in {category.superCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {subcatSearchResult.length > 0 && (
                                  <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                    {subcatSearchResult &&
                                      subcatSearchResult.map(
                                        (sub_category, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      sub_category.desktopImage
                                                    }
                                                    src={
                                                      sub_category.desktopImage
                                                    }
                                                    alt={sub_category.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                                  >
                                                    {sub_category.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in{" "}
                                                    {sub_category.superCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                                {categoryTagSearchResult.length > 0 && (
                                  <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                    {categoryTagSearchResult &&
                                      categoryTagSearchResult.map(
                                        (category_tag, index) => (
                                          <li className="item" key={index}>
                                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                              <div className="mini-image text-center">
                                                <Link
                                                  className="item-link"
                                                  to={`/tag-search/${category_tag.slugUrl}`}
                                                >
                                                  <img
                                                    className="blur-up lazyload"
                                                    data-src={
                                                      category_tag.desktopImage
                                                    }
                                                    src={
                                                      category_tag.desktopImage
                                                    }
                                                    alt={category_tag.name}
                                                    title="product"
                                                    width={120}
                                                    height={170}
                                                  />
                                                </Link>
                                              </div>
                                              <div className="ms-3 details text-left">
                                                <div className="product-name">
                                                  <Link
                                                    className="item-title"
                                                    to={`/tag-search/${category_tag.slugUrl}`}
                                                  >
                                                    {category_tag.name}
                                                  </Link>
                                                </div>

                                                <div className="product-review d-flex align-items-center justify-content-start">
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      color: "#2f415d",
                                                    }}
                                                  >
                                                    in{" "}
                                                    {category_tag.SubCategory}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="account-parent iconset">
                      <div className="account-link" title="Account">
                        <i className="hdr-icon icon anm anm-user-al" />
                      </div>
                      <div id="accountBox">
                        <div className="customer-links">
                          <ul className="m-0">
                            {isAuth === true ? (
                              <>
                                <li>
                                  <Link to="/my-account">
                                    <i className="icon anm anm-user-cil" />
                                    My Account
                                  </Link>
                                </li>
                              </>
                            ) : (
                              <>
                                <li
                                  onClick={() => {
                                    dispatch(
                                      set_checkout_authentication_status(0)
                                    );
                                    navigate("/login");
                                  }}
                                >
                                  <Link to="/login">
                                    <i className="icon anm anm-user-cil" />
                                    Sign In
                                  </Link>
                                </li>
                              </>
                            )}

                            <li>
                              <Link to="/">
                                <i className="icon anm anm-sign-out-al" />
                                Sign out
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="header-cart iconset" title="Cart">
                      <Link
                        className="header-cart btn-minicart clr-none"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#minicart-drawer"
                      >
                        <i className="hdr-icon icon anm anm-cart-l" />
                        <span className="cart-count">{CartItems.length}</span>
                      </Link>

                      <div
                        id="minicart-drawer"
                        className="minicart-right-drawer offcanvas offcanvas-end"
                        tabIndex={-1}
                      >
                        {CartItems.length === 0 ? (
                          <>
                            <div
                              id="cartEmpty"
                              className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                            >
                              <div className="minicart-header d-flex-center justify-content-between w-100">
                                <h4 className="fs-6">
                                  Your cart ({CartItems.length} Items)
                                </h4>
                                <button
                                  className="close-cart border-0"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  <i
                                    className="icon anm anm-times-r"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Close"
                                  />
                                </button>
                              </div>
                              <div className="cartEmpty-content mt-4">
                                <i className="icon anm anm-cart-l fs-1 text-muted" />
                                <p className="my-3">No Products in the Cart</p>
                                <span
                                  className="btn btn-primary cart-btn"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                  onClick={() => navigate("/")}
                                >
                                  Continue shopping
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div id="cart-drawer" className="block block-cart">
                              <div className="minicart-header">
                                <button
                                  className="close-cart border-0"
                                  data-bs-dismiss="offcanvas"
                                  aria-label="Close"
                                >
                                  <i
                                    className="icon anm anm-times-r"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    title="Close"
                                  />
                                </button>
                                <h4 className="fs-6">
                                  Your cart ({CartItems.length} Items)
                                </h4>
                              </div>
                              <div className="minicart-content">
                                <ul className="m-0 clearfix">
                                  {CartItems &&
                                    CartItems.map((item, index) => (
                                      <li
                                        className="item d-flex justify-content-center align-items-center"
                                        key={index}
                                      >
                                        <span className="product-image rounded-3">
                                          <img
                                            className="blur-up lazyload"
                                            data-src={item.thumbnail}
                                            src={item.thumbnail}
                                            alt="product"
                                            title="Product"
                                            width={120}
                                            height={170}
                                          />
                                        </span>
                                        <div className="product-details">
                                          <span
                                            className="product-title limited-paragraph "
                                            onClick={() => {
                                              if ("slugurl" in item) {
                                                navigate(
                                                  `/product-info/${item.slugurl}`
                                                );
                                              }
                                            }}
                                          >
                                            {item.ProductName}
                                          </span>
                                          <div className="variant-cart my-2 limited-paragraph">
                                            {item.packSizeOptionName} /{" "}
                                            {item.packSizeName}
                                          </div>
                                          <div className="priceRow">
                                            <div className="product-price">
                                              <span className="price">
                                                {" "}
                                                ₹{item.Price}
                                              </span>{" "}
                                              x{" "}
                                              <span className="price">
                                                {" "}
                                                {item.cart_Quentity}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              <div className="minicart-bottom">
                                <div className="shipinfo">
                                  {amountToGetfeeDelivery !== 0 && (
                                    <div className="progress mb-2">
                                      <div
                                        className="progress-bar progress-bar-striped progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow={70}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{
                                          width: `${amountToGetfeeDeliveryPercentage}%`,
                                        }}
                                      >
                                        {amountToGetfeeDeliveryPercentage}%
                                      </div>
                                    </div>
                                  )}

                                  {amountToGetfeeDeliveryPercentage === 0 ? (
                                    <>
                                      <div className="freeShipMsg">
                                        <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                        Only{" "}
                                        <span
                                          className="money"
                                          data-currency-usd=" ₹199.00"
                                          data-currency="USD"
                                        >
                                          {" "}
                                          ₹{amountToGetfeeDelivery}
                                        </span>{" "}
                                        away from
                                        <b>Free Shipping</b>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="freeShipMsg d-block">
                                        <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                        Congrats! You are eligible for
                                        <b>Free Shipping</b>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="subtotal clearfix my-3">
                                  <div className="totalInfo clearfix mb-1 d-block">
                                    <span>Total:</span>
                                    <span className="item product-price">
                                      {" "}
                                      ₹{TotalPrice}
                                    </span>
                                  </div>
                                  <div className="totalInfo clearfix mb-1 d-block">
                                    <span>Handlling Fee:</span>
                                    <span className="item product-price">
                                      {" "}
                                      ₹{HandllingFee}
                                    </span>
                                  </div>
                                  <div className="totalInfo clearfix">
                                    <span>Netpayable:</span>
                                    <span className="item product-price">
                                      {" "}
                                      ₹{TotalAmount}
                                    </span>
                                  </div>
                                </div>

                                <div className="minicart-action d-flex mt-3">
                                  <span
                                    onClick={() => navigate("/check-out")}
                                    className="proceed-to-checkout btn btn-primary w-50 me-1"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    Check Out
                                  </span>
                                  <span
                                    onClick={() => navigate("/cart")}
                                    className="cart-btn btn btn-secondary w-50 ms-1"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    View Cart
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header> */}
          </div>
        </Affix>
        <div className="mainheader" style={{ backgroundColor: "#2F415D" }}>
          <button className="menuToggle" onClick={toggleDrawer}>
            <FaBars style={{ fontSize: "1.5rem", color: "#fff" }} />
          </button>
          <div>
            <div className={`drawer ${drawerOpen ? "open" : ""}`}>
              <div className="" style={{ backgroundColor: "#2F415D",display:"flex"}}>
                <Link className="logoImg" to="/">
                  <img
                    src="/assets/images/logonew14.png"
                    alt="EwShopping"
                    title="EwShopping"
                    className="imglogo"
                  />
                </Link>
                <span
                  style={{
                    color: "white",
                    fontSize: "28px",
                    fontWeight: "bold",
                    marginLeft:"2rem"

                  }}
                  onClick={() => setDrawerOpen(false)}
                >
                  <RxCross2 />
                </span>
              </div>
              <div
                style={{ backgroundColor: "#2F415D" }}
                className="increasebackgroundcolor"
              >
                <div>
                  {" "}
                  {mainHomeSliderTotal &&
                    mainHomeSliderTotal.map((indCat) => (
                      <Link to={`/category/${indCat.slugUrl}`}>
                        <h1
                          onClick={toggleDrawer}
                          className="categorynametext1"
                        >
                          {indCat.name.toUpperCase()}
                        </h1>
                      </Link>
                    ))}
                </div>
              </div>

              {/* <Link>
              <div onClick={toggleDrawer}>WOMEN</div>  
            </Link> */}
            </div>
          </div>
          <div className="rightHeader">
            <div className="inputDivision">
              <input
                placeholder="Search for products..."
                type="text"
                value={search}
                onChange={searchchange}
              />
              <span
                style={{
                  color: "#fff",
                  fontWeight: "800",
                  backgroundColor: "#febd69",

                  height: "40px",
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                <FaSearch
                  style={{ fontSize: "18px" }}
                  type="submit"
                  onClick={serchclick}
                />
              </span>

              {showResults && (
                <>
                  <div className="serchsugist" style={{ width: "300px" }}>
                    {categorySearchResult.length > 0 && (
                      <ul className="">
                        {categorySearchResult &&
                          categorySearchResult.map((category, index) => (
                            <li className="item" key={index}>
                              <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                <div className="mini-image text-center">
                                  <Link
                                    className="item-link"
                                    to={`/search/${category.superCategory}/${category.slugUrl}`}
                                    onClick={() => setShowResults(false)}
                                  >
                                    <img
                                      className="blur-up lazyload"
                                      data-src={category.desktopImage}
                                      src={category.desktopImage}
                                      alt={category.name}
                                      title="product"
                                      width={120}
                                      height={170}
                                    />
                                  </Link>
                                </div>
                                <div className="ms-3 details text-left">
                                  <div className="product-name">
                                    <Link
                                      className="item-title"
                                      to={`/search/${category.superCategory}/${category.slugUrl}`}
                                      onClick={() => setShowResults(false)}
                                    >
                                      {category.name}
                                    </Link>
                                  </div>

                                  <div className="product-review d-flex align-items-center justify-content-start">
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#2f415d",
                                      }}
                                    >
                                      in {category.superCategory}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    )}
                    {subcatSearchResult.length > 0 && (
                      <ul>
                        {subcatSearchResult &&
                          subcatSearchResult.map((sub_category, index) => (
                            <li className="item" key={index}>
                              <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                <div className="mini-image text-center">
                                  <Link
                                    className="item-link"
                                    to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                    onClick={() => setShowResults(false)}
                                  >
                                    <img
                                      className="blur-up lazyload"
                                      data-src={sub_category.desktopImage}
                                      src={sub_category.desktopImage}
                                      alt={sub_category.name}
                                      title="product"
                                      width={120}
                                      height={170}
                                    />
                                  </Link>
                                </div>
                                <div className="ms-3 details text-left">
                                  <div className="product-name">
                                    <Link
                                      className="item-title"
                                      to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                      onClick={() => setShowResults(false)}
                                    >
                                      {sub_category.name}
                                    </Link>
                                  </div>

                                  <div className="product-review d-flex align-items-center justify-content-start">
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#2f415d",
                                      }}
                                    >
                                      in {sub_category.superCategory}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    )}
                    {categoryTagSearchResult.length > 0 && (
                      <ul>
                        {categoryTagSearchResult &&
                          categoryTagSearchResult.map((category_tag, index) => (
                            <li className="item" key={index}>
                              <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                <div className="mini-image text-center">
                                  <Link
                                    className="item-link"
                                    to={`/tag-search/${category_tag.slugUrl}`}
                                    onClick={() => setShowResults(false)}
                                  >
                                    <img
                                      className="blur-up lazyload"
                                      data-src={category_tag.desktopImage}
                                      src={category_tag.desktopImage}
                                      alt={category_tag.name}
                                      title="product"
                                      width={120}
                                      height={170}
                                    />
                                  </Link>
                                </div>
                                <div className="ms-3 details text-left">
                                  <div className="product-name">
                                    <Link
                                      className="item-title"
                                      to={`/tag-search/${category_tag.slugUrl}`}
                                      onClick={() => setShowResults(false)}
                                    >
                                      {category_tag.name}
                                    </Link>
                                  </div>

                                  <div className="product-review d-flex align-items-center justify-content-start">
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#2f415d",
                                      }}
                                    >
                                      in {category_tag.SubCategory}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                </>
              )}
            </div>

            {/* <div className="accountpieces account-parent iconset">
              <LuUser />
              <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                Profile
              </span>
            </div> */}
            {/* <div className="accountpieces">
              <CiHeart />
              <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                Wishlist
              </span>
            </div> */}
            {/* <div className="accountpieces">
              <MdOutlineShoppingBag />
              <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                Bag
              </span>
            </div> */}
          </div>
          <div
            className="logo col-2 col-sm-3 col-md-3 col-lg-2 align-self-center is-fixed mobileVersion seller_icon"
            style={{ marginLeft: "30px" }}
          >
            <Link
              className=""
              to="https://seller.ewshopping.com"
              target="_blank"
            >
              <div
                style={{
                  height: "38px",
                  width: "38px",
                  backgroundColor: "#febd69",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <FaShoppingBag style={{ fontSize: "24px" }} />
              </div>
            </Link>
          </div>
        </div>

        <div className="desktopHeader" style={{ backgroundColor: "#2F415D" }}>
          <div className="leftHeaderDesktop">
            <div>
              <div className="logo col-5 col-sm-3 col-md-2 col-lg-3 align-self-center is-fixed desktopVersion">
                <Link className="logoImg" to="/">
                  <img
                    src="/assets/images/logonew14.png"
                    alt="EwShopping"
                    title="EwShopping"
                    className="imglogo"
                  />
                </Link>
              </div>
            </div>
            {mainHomeSliderTotal &&
              mainHomeSliderTotal.map((indCat) => (
                <Link to={`/category/${indCat.slugUrl}`}>
                  <h1 className="categorynametext">
                    {indCat.name.toUpperCase()}
                  </h1>
                </Link>
              ))}
          </div>
          <div className="rightHeaderDesktop">
            <div
              className="inputDivisionDesktop"
              onSubmit={(e) => searchsubmit(e)}
            >
              <span>
                <CiSearch
                  style={{ fontSize: "1.5rem" }}
                  type="submit"
                  onClick={serchclick}
                />
              </span>
              <input
                placeholder="search for products...."
                type="text"
                value={search}
                onChange={searchchange}
              />
            </div>
            {showResults && (
              <>
                <div className="serchsugist">
                  {categorySearchResult.length > 0 && (
                    <ul className="">
                      {categorySearchResult &&
                        categorySearchResult.map((category, index) => (
                          <li className="item" key={index}>
                            <div
                              className="mini-list-item d-flex align-items-center w-100 clearfix"
                              onClick={() => catSearchClick(category)}
                            >
                              <div className="mini-image text-center">
                                <Link className="item-link">
                                  <img
                                    className="blur-up lazyload"
                                    data-src={category.desktopImage}
                                    src={category.desktopImage}
                                    alt={category.name}
                                    title="product"
                                    width={120}
                                    height={170}
                                  />
                                </Link>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <Link className="item-title">
                                    {category.name}
                                  </Link>
                                </div>

                                <Link>
                                  <div className="product-review d-flex align-items-center justify-content-start">
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#2f415d",
                                      }}
                                    >
                                      in {category.superCategory}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                  {subcatSearchResult.length > 0 && (
                    <ul>
                      {subcatSearchResult &&
                        subcatSearchResult.map((sub_category, index) => (
                          <li className="item" key={index}>
                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                              <div className="mini-image text-center">
                                <Link
                                  className="item-link"
                                  to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                  onClick={() => setShowResults(false)}
                                >
                                  <img
                                    className="blur-up lazyload"
                                    data-src={sub_category.desktopImage}
                                    src={sub_category.desktopImage}
                                    alt={sub_category.name}
                                    title="product"
                                    width={120}
                                    height={170}
                                  />
                                </Link>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <Link
                                    className="item-title"
                                    to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                    onClick={() => setShowResults(false)}
                                  >
                                    {sub_category.name}
                                  </Link>
                                </div>

                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#2f415d",
                                    }}
                                  >
                                    in {sub_category.superCategory}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                  {categoryTagSearchResult.length > 0 && (
                    <ul>
                      {categoryTagSearchResult &&
                        categoryTagSearchResult.map((category_tag, index) => (
                          <li className="item" key={index}>
                            <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                              <div className="mini-image text-center">
                                <Link
                                  className="item-link"
                                  to={`/tag-search/${category_tag.slugUrl}`}
                                  onClick={() => setShowResults(false)}
                                >
                                  <img
                                    className="blur-up lazyload"
                                    data-src={category_tag.desktopImage}
                                    src={category_tag.desktopImage}
                                    alt={category_tag.name}
                                    title="product"
                                    width={120}
                                    height={170}
                                  />
                                </Link>
                              </div>
                              <div className="ms-3 details text-left">
                                <div className="product-name">
                                  <Link
                                    className="item-title"
                                    to={`/tag-search/${category_tag.slugUrl}`}
                                    onClick={() => setShowResults(false)}
                                  >
                                    {category_tag.name}
                                  </Link>
                                </div>

                                <div className="product-review d-flex align-items-center justify-content-start">
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#2f415d",
                                    }}
                                  >
                                    in {category_tag.SubCategory}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </>
            )}

            <div className="accountpiecesDesk account-parent iconset">
              {/* <LuUser className="accountpiecesDeskicon  anm-user-al" />
              <span className="accountpiecesDesktext anm anm-user-al">Profile</span> */}
              <div className="account-link" title="Account">
                <i className="hdr-icon icon anm anm-user-al" />
              </div>
              <div id="accountBox">
                <div className="customer-links">
                  <ul className="m-0">
                    {isAuth === true ? (
                      <>
                        <li>
                          <Link to="/my-account">
                            <i className="icon anm anm-user-cil" />
                            My Account
                          </Link>
                        </li>
                        <li onClick={() => signoutClick()}>
                          <i className="icon anm anm-sign-out-al" />
                          Sign out
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          onClick={() => {
                            dispatch(set_checkout_authentication_status(0));
                            navigate("/login");
                          }}
                        >
                          <Link to="/login">
                            <i className="icon anm anm-user-cil" />
                            Sign In
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div>
              {/* <MdOutlineShoppingBag className="accountpiecesDeskicon" />
              <span className="accountpiecesDesktext">Bag</span> */}
              <div
                className="accountpiecesDesk header-cart iconset"
                title="Cart"
              >
                <Link
                  className="header-cart btn-minicart clr-none"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#minicart-drawer"
                >
                  <i className="hdr-icon icon anm anm-cart-l" />
                  <span className="cart-count">{CartItems.length}</span>
                </Link>

                <div
                  id="minicart-drawer"
                  className="minicart-right-drawer offcanvas offcanvas-end"
                  tabIndex={-1}
                >
                  {CartItems.length === 0 ? (
                    <>
                      <div
                        id="cartEmpty"
                        className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                      >
                        <div className="minicart-header d-flex-center justify-content-between w-100">
                          <h4 className="fs-6">
                            Your cart ({CartItems.length} Items)
                          </h4>
                          <button
                            className="close-cart border-0"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            <i
                              className="icon anm anm-times-r"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Close"
                            />
                          </button>
                        </div>
                        <div className="cartEmpty-content mt-4">
                          <i className="icon anm anm-cart-l fs-1 text-muted" />
                          <p className="my-3">No Products in the Cart</p>
                          <span
                            className="btn btn-primary cart-btn"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            onClick={() => navigate("/")}
                          >
                            Continue shopping
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div id="cart-drawer" className="block block-cart">
                        <div className="minicart-header">
                          <button
                            className="close-cart border-0"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            <i
                              className="icon anm anm-times-r"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Close"
                            />
                          </button>
                          <h4 className="fs-6">
                            Your cart ({CartItems.length} Items)
                          </h4>
                        </div>
                        <div className="minicart-content">
                          <ul className="m-0 clearfix">
                            {CartItems &&
                              CartItems.map((item, index) => (
                                <li
                                  className="item d-flex justify-content-center align-items-center"
                                  key={index}
                                >
                                  <span className="product-image rounded-3">
                                    <img
                                      className="blur-up lazyload"
                                      data-src={item.thumbnail}
                                      src={item.thumbnail}
                                      alt="product"
                                      title="Product"
                                      width={120}
                                      height={170}
                                    />
                                  </span>
                                  <div className="product-details">
                                    <span
                                      className="product-title limited-paragraph"
                                      onClick={() => {
                                        if ("slugurl" in item) {
                                          navigate(
                                            `/product-info/${item.slugurl}`
                                          );
                                        }
                                      }}
                                      data-bs-dismiss="offcanvas"
                                      aria-label="Close"
                                    >
                                      {item.ProductName}
                                    </span>
                                    <div className="variant-cart my-2 limited-paragraph">
                                      {item.packSizeOptionName} /{" "}
                                      {item.packSizeName}
                                    </div>
                                    <div className="priceRow">
                                      <div className="product-price">
                                        <span className="price">
                                          {" "}
                                          ₹{item.Price}
                                        </span>{" "}
                                        x{" "}
                                        <span className="price">
                                          {" "}
                                          {item.cart_Quentity}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="minicart-bottom">
                          <div className="shipinfo">
                            {amountToGetfeeDelivery !== 0 && (
                              <div className="progress mb-2">
                                <div
                                  className="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  aria-valuenow={70}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                  style={{
                                    width: `${amountToGetfeeDeliveryPercentage}%`,
                                  }}
                                >
                                  {amountToGetfeeDeliveryPercentage}%
                                </div>
                              </div>
                            )}

                            {amountToGetfeeDeliveryPercentage === 0 ? (
                              <>
                                <div className="freeShipMsg">
                                  <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                  Only{" "}
                                  <span
                                    className="money"
                                    data-currency-usd=" ₹199.00"
                                    data-currency="USD"
                                  >
                                    {" "}
                                    ₹{amountToGetfeeDelivery}
                                  </span>{" "}
                                  away from
                                  <b>Free Shipping</b>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="freeShipMsg d-block">
                                  <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                  Congrats! You are eligible for
                                  <b>Free Shipping</b>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="subtotal clearfix my-3">
                            <div className="totalInfo clearfix mb-1 d-block">
                              <span>Total:</span>
                              <span className="item product-price">
                                {" "}
                                ₹{TotalPrice}
                              </span>
                            </div>
                            <div className="totalInfo clearfix mb-1 d-block">
                              <span>Handlling Fee:</span>
                              <span className="item product-price">
                                {" "}
                                ₹{HandllingFee}
                              </span>
                            </div>
                            <div className="totalInfo clearfix">
                              <span>Netpayable:</span>
                              <span className="item product-price">
                                {" "}
                                ₹{TotalAmount}
                              </span>
                            </div>
                          </div>

                          <div className="minicart-action d-flex mt-3">
                            <span
                              onClick={() => navigate("/check-out")}
                              className="proceed-to-checkout btn btn-primary w-50 me-1"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Check Out
                            </span>
                            <span
                              onClick={() => navigate("/cart")}
                              className="cart-btn btn btn-secondary w-50 ms-1"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              View Cart
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="accountpiecesDesk"
              onClick={() =>
                window.open(`https://seller.ewshopping.com`, "_blank")
              }
            >
              <span className="accountpiecesDesktext">Sell on EWShopping</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hadear;
