import React from "react";

const ElecdivonicSubcat = ({ productifo }) => {
  return (
    <div className="design1"
      // style={{
      //   display: "flex",
      //   flexWrap: "wrap",
        
      // }}
    >
      {productifo.electronics.in_the_box !== "" && (
        <div className="des">
          <h5>In the box</h5>
          <p>{productifo.electronics.in_the_box}</p>
        </div>
      )}
      {productifo.electronics.model_number !== "" && (
        <div className="des">
          <h5>model number</h5>
          <p>{productifo.electronics.model_number}</p>
        </div>
      )}

      {productifo.electronics.model_name !== "" && (
        <div className="des">
          <h5>model name</h5>
          <p>{productifo.electronics.model_name}</p>
        </div>
      )}
      {productifo.electronics.color !== "" && (
        <div className="des">
          <h5>color</h5>
          <p>{productifo.electronics.color}</p>
        </div>
      )}
      {productifo.electronics.browse_type !== "" && (
        <div className="des">
          <h5>browse type</h5>
          <p>{productifo.electronics.browse_type}</p>
        </div>
      )}
      {productifo.electronics.sim_type !== "" && (
        <div className="des">
          <h5>sim type</h5>
          <p>{productifo.electronics.sim_type}</p>
        </div>
      )}
      {productifo.electronics.hybrid_sim_slot !== "" && (
        <div className="des">
          <h5>hybrid sim slot</h5>
          <p>{productifo.electronics.hybrid_sim_slot}</p>
        </div>
      )}
      {productifo.electronics.touchscreen !== "" && (
        <div className="des">
          <h5>touch screen</h5>
          <p>{productifo.electronics.touchscreen}</p>
        </div>
      )}
      {productifo.electronics.otg_compatible !== "" && (
        <div className="des">
          <h5>Otg compatible</h5>
          <p>{productifo.electronics.otg_compatible}</p>
        </div>
      )}
      {productifo.electronics.quick_charging !== "" && (
        <div className="des">
          <h5>Quick charging</h5>
          <p>{productifo.electronics.quick_charging}</p>
        </div>
      )}
      {productifo.electronics.sar_value !== "" && (
        <div className="des">
          <h5>Sar value</h5>
          <p>{productifo.electronics.sar_value}</p>
        </div>
      )}
      {productifo.electronics.display_size !== "" && (
        <div className="des">
          <h5>Display size</h5>
          <p>{productifo.electronics.display_size}</p>
        </div>
      )}
      {productifo.electronics.resolution !== "" && (
        <div className="des">
          <h5>Resolution</h5>
          <p>{productifo.electronics.resolution}</p>
        </div>
      )}
      {productifo.electronics.gpu !== "" && (
        <div className="des">
          <h5>gpu</h5>
          <p>{productifo.electronics.gpu}</p>
        </div>
      )}
      {productifo.electronics.display_type !== "" && (
        <div className="des">
          <h5>Display type</h5>
          <p>{productifo.electronics.display_type}</p>
        </div>
      )}
      {productifo.electronics.other_display_features !== "" && (
        <div className="des">
          <h5>Other display features</h5>
          <p>{productifo.electronics.other_display_features}</p>
        </div>
      )}
      {productifo.electronics.operating_system !== "" && (
        <div className="des">
          <h5>operating system</h5>
          <p>{productifo.electronics.operating_system}</p>
        </div>
      )}
      {productifo.electronics.processor_brand !== "" && (
        <div className="des">
          <h5>Processor brand</h5>
          <p>{productifo.electronics.processor_brand}</p>
        </div>
      )}
      {productifo.electronics.processor_type !== "" && (
        <div className="des">
          <h5>Processor type</h5>
          <p>{productifo.electronics.processor_type}</p>
        </div>
      )}
      {productifo.electronics.primary_clock_speed !== "" && (
        <div className="des">
          <h5>Primary clock speed</h5>
          <p>{productifo.electronics.primary_clock_speed}</p>
        </div>
      )}
      {productifo.electronics.secondary_clock_speed !== "" && (
        <div className="des">
          <h5>Secondary clock speed</h5>
          <p>{productifo.electronics.secondary_clock_speed}</p>
        </div>
      )}
      {productifo.electronics.tertiary_clock_speed !== "" && (
        <div className="des">
          <h5>Tertiary clock speed</h5>
          <p>{productifo.electronics.tertiary_clock_speed}</p>
        </div>
      )}
      {productifo.electronics.operating_frequency !== "" && (
        <div className="des">
          <h5>Operating frequency</h5>
          <p>{productifo.electronics.operating_frequency}</p>
        </div>
      )}
      {productifo.electronics.internal_storage !== "" && (
        <div className="des">
          <h5>Internal storage</h5>
          <p>{productifo.electronics.internal_storage}</p>
        </div>
      )}
      {productifo.electronics.ram !== "" && (
        <div className="des">
          <h5>ram</h5>
          <p>{productifo.electronics.ram}</p>
        </div>
      )}
      {productifo.electronics.total_memory !== "" && (
        <div className="des">
          <h5>Total memory</h5>
          <p>{productifo.electronics.total_memory}</p>
        </div>
      )}
      {productifo.electronics.call_log_memory !== "" && (
        <div className="des">
          <h5>Call log memory</h5>
          <p>{productifo.electronics.call_log_memory}</p>
        </div>
      )}
      {productifo.electronics.primary_camera_available !== "" && (
        <div className="des">
          <h5>Primary camera available</h5>
          <p>{productifo.electronics.primary_camera_available}</p>
        </div>
      )}
      {productifo.electronics.primary_camera !== "" && (
        <div className="des">
          <h5>Primary camera</h5>
          <p>{productifo.electronics.primary_camera}</p>
        </div>
      )}
      {productifo.electronics.primary_camera_features !== "" && (
        <div className="des">
          <h5>Primary camera features</h5>
          <p>{productifo.electronics.primary_camera_features}</p>
        </div>
      )}
      {productifo.electronics.secondary_camera_available !== "" && (
        <div className="des">
          <h5>Secondary camera available</h5>
          <p>{productifo.electronics.secondary_camera_available}</p>
        </div>
      )}
      {productifo.electronics.secondary_camera !== "" && (
        <div className="des">
          <h5>Secondary camera</h5>
          <p>{productifo.electronics.secondary_camera}</p>
        </div>
      )}
      {productifo.electronics.secondary_camera_features !== "" && (
        <div className="des">
          <h5>Secondary camera features</h5>
          <p>{productifo.electronics.secondary_camera_features}</p>
        </div>
      )}
      {productifo.electronics.flash !== "" && (
        <div className="des">
          <h5>Flash</h5>
          <p>{productifo.electronics.flash}</p>
        </div>
      )}
      {productifo.electronics.hd_recording !== "" && (
        <div className="des">
          <h5>HD recording</h5>
          <p>{productifo.electronics.hd_recording}</p>
        </div>
      )}
      {productifo.electronics.full_hd_recording !== "" && (
        <div className="des">
          <h5>Full hd recording</h5>
          <p>{productifo.electronics.full_hd_recording}</p>
        </div>
      )}
      {productifo.electronics.video_recording !== "" && (
        <div className="des">
          <h5>Video recording</h5>
          <p>{productifo.electronics.video_recording}</p>
        </div>
      )}
      {productifo.electronics.video_recording_resolution !== "" && (
        <div className="des">
          <h5>Video recording resolution</h5>
          <p>{productifo.electronics.video_recording_resolution}</p>
        </div>
      )}
      {productifo.electronics.digital_zoom !== "" && (
        <div className="des">
          <h5>Digital zoom</h5>
          <p>{productifo.electronics.digital_zoom}</p>
        </div>
      )}
      {productifo.electronics.frame_rate !== "" && (
        <div className="des">
          <h5>Frame rate</h5>
          <p>{productifo.electronics.frame_rate}</p>
        </div>
      )}
      {productifo.electronics.dual_camera_lens !== "" && (
        <div className="des">
          <h5>Dual camera lens</h5>
          <p>{productifo.electronics.dual_camera_lens}</p>
        </div>
      )}
      {productifo.electronics.call_wait_or_hold !== "" && (
        <div className="des">
          <h5>Call wait or hold</h5>
          <p>{productifo.electronics.call_wait_or_hold}</p>
        </div>
      )}
      {productifo.electronics.conference_call !== "" && (
        <div className="des">
          <h5>Conference call</h5>
          <p>{productifo.electronics.conference_call}</p>
        </div>
      )}
      {productifo.electronics.hands_free !== "" && (
        <div className="des">
          <h5>Hands free</h5>
          <p>{productifo.electronics.hands_free}</p>
        </div>
      )}
      {productifo.electronics.call_divert !== "" && (
        <div className="des">
          <h5>Call divert</h5>
          <p>{productifo.electronics.call_divert}</p>
        </div>
      )}
      {productifo.electronics.phone_book !== "" && (
        <div className="des">
          <h5>Phone book</h5>
          <p>{productifo.electronics.phone_book}</p>
        </div>
      )}
      {productifo.electronics.call_timer !== "" && (
        <div className="des">
          <h5>Call timer</h5>
          <p>{productifo.electronics.call_timer}</p>
        </div>
      )}
      {productifo.electronics.speaker_phone !== "" && (
        <div className="des">
          <h5>Speaker phone</h5>
          <p>{productifo.electronics.speaker_phone}</p>
        </div>
      )}
      {productifo.electronics.call_records !== "" && (
        <div className="des">
          <h5>Call records</h5>
          <p>{productifo.electronics.call_records}</p>
        </div>
      )}
      {productifo.electronics.network_type !== "" && (
        <div className="des">
          <h5>Network type</h5>
          <p>{productifo.electronics.network_type}</p>
        </div>
      )}
      {productifo.electronics.supported_networks !== "" && (
        <div className="des">
          <h5>Supported networks</h5>
          <p>{productifo.electronics.supported_networks}</p>
        </div>
      )}
      {productifo.electronics.internet_connectivity !== "" && (
        <div className="des">
          <h5>Internet connectivity</h5>
          <p>{productifo.electronics.internet_connectivity}</p>
        </div>
      )}
      {productifo.electronics._3g !== "" && (
        <div className="des">
          <h5>_3g</h5>
          <p>{productifo.electronics._3g}</p>
        </div>
      )}
      {productifo.electronics.gprs !== "" && (
        <div className="des">
          <h5>gprs</h5>
          <p>{productifo.electronics.gprs}</p>
        </div>
      )}
      {productifo.electronics.micro_usb_port !== "" && (
        <div className="des">
          <h5>Micro usb port</h5>
          <p>{productifo.electronics.micro_usb_port}</p>
        </div>
      )}
      {productifo.electronics.micro_usb_version !== "" && (
        <div className="des">
          <h5>Micro usb version</h5>
          <p>{productifo.electronics.micro_usb_version}</p>
        </div>
      )}
      {productifo.electronics.blutooth_support !== "" && (
        <div className="des">
          <h5>Blutooth support</h5>
          <p>{productifo.electronics.blutooth_support}</p>
        </div>
      )}
      {productifo.electronics.blutooth_version !== "" && (
        <div className="des">
          <h5>Blutooth version</h5>
          <p>{productifo.electronics.blutooth_version}</p>
        </div>
      )}
      {productifo.electronics.wifi !== "" && (
        <div className="des">
          <h5>wifi</h5>
          <p>{productifo.electronics.wifi}</p>
        </div>
      )}
      {productifo.electronics.wifi_version !== "" && (
        <div className="des">
          <h5>wifi version</h5>
          <p>{productifo.electronics.wifi_version}</p>
        </div>
      )}
      {productifo.electronics.wifi_hotspot !== "" && (
        <div className="des">
          <h5>wifi hotspot</h5>
          <p>{productifo.electronics.wifi_hotspot}</p>
        </div>
      )}
      {productifo.electronics.mini_hdmi_port !== "" && (
        <div className="des">
          <h5>Mini hdmi port</h5>
          <p>{productifo.electronics.mini_hdmi_port}</p>
        </div>
      )}
      {productifo.electronics.nfc !== "" && (
        <div className="des">
          <h5>nfc</h5>
          <p>{productifo.electronics.nfc}</p>
        </div>
      )}
      {productifo.electronics.usb_tethering !== "" && (
        <div className="des">
          <h5>Usb teh5ering</h5>
          <p>{productifo.electronics.usb_tethering}</p>
        </div>
      )}
     
    </div>
  );
};

export default ElecdivonicSubcat;
