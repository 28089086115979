import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SingleProduct from "../../component/product/SingleProduct";
import { useSelector } from "react-redux";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const TopProducts = () => {
  const {
    clothingRecommendedProducts,
    clothingTrendingProduct,
    clothingHotProduct,
  } = useSelector((store) => store.product);

  const [trending_Products, setTrending_Products] = useState([]);
  const [hot_Products, sethot_Products] = useState([]);
  const [recommend_Products, setrecommend_Products] = useState([]);

  useEffect(() => {
    const getTrendingProducts = async () => {
      const url = `${Baseurl}/api/v1/product/trending-random-products/Kids`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        const responce = resp.data.products;
        setTrending_Products(responce);
      }
    };
    getTrendingProducts();
  }, []);
  useEffect(() => {
    const getHotProducts = async () => {
      const url = `${Baseurl}/api/v1/product/trending-random-products/Men`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        const responce = resp.data.products;
        sethot_Products(responce);
      }
    };
    getHotProducts();
  }, []);
  useEffect(() => {
    const getRecommendedProducts = async () => {
      const url = `${Baseurl}/api/v1/product/trending-random-products/Women`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        const responce = resp.data.products;
        setrecommend_Products(responce);
      }
    };
    getRecommendedProducts();
  }, []);

  return (
    <>
      {trending_Products.length + hot_Products.length + recommend_Products.length > 0 && (
        <section
          className="section product-slider tab-slider-product"
          style={{ paddingBottom: "10px" }}
        >
          <div className="container">
            <div className="section-header d-none">
              <h2>Special Offers</h2>
              <p>Browse the huge variety of our best seller</p>
            </div>
            <div className="tabs-listing">
              <ul
                className="nav nav-tabs style1 justify-content-center"
                id="productTabs"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link head-font active"
                    id="bestsellers-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#bestsellers"
                    type="button"
                    role="tab"
                    aria-controls="bestsellers"
                    aria-selected="true"
                    style={{
                      fontFamily: "Ga Maamli,sans-serif",
                      color: "#315999",
                    }}
                  >
                    Bestseller
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link head-font TitleHeading"
                    id="newarrivals-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#newarrivals"
                    type="button"
                    role="tab"
                    aria-controls="newarrivals"
                    aria-selected="false"
                    style={{
                      fontFamily: "Ga Maamli,sans-serif",
                      color: "#315999",
                    }}
                  >
                    New Arrivals
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link head-font"
                    id="toprated-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#toprated"
                    type="button"
                    role="tab"
                    aria-controls="toprated"
                    aria-selected="false"
                    style={{
                      fontFamily: "Ga Maamli,sans-serif",
                      color: "#315999",
                    }}
                  >
                    Top Rated
                  </button>
                </li>
              </ul>
              <div
                class="tab-content"
                id="productTabsContent"
                style={{ marginTop: "30px" }}
              >
                <div
                  class="tab-pane show active"
                  id="bestsellers"
                  role="tabpanel"
                  aria-labelledby="bestsellers-tab"
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 main-col">
                    <div className="grid-products grid-view-items">
                      <div className="row col-row product-options row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                        {recommend_Products &&
                          recommend_Products
                            .slice(0, 10)
                            .map((product, index) => (
                              <SingleProduct key={index} product={product} />
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane"
                  id="newarrivals"
                  role="tabpanel"
                  aria-labelledby="newarrivals-tab"
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 main-col">
                    <div className="grid-products grid-view-items">
                      <div className="row col-row product-options row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                        {hot_Products &&
                          hot_Products
                            .slice(0, 10)
                            .map((product, index) => (
                              <SingleProduct key={index} product={product} />
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane"
                  id="toprated"
                  role="tabpanel"
                  aria-labelledby="toprated-tab"
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 main-col">
                    <div className="grid-products grid-view-items">
                      <div className="row col-row product-options row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                        {trending_Products &&
                          trending_Products.map((product, index) => (
                            <SingleProduct key={index} product={product} />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default TopProducts;
