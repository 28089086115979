import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Orders = () => {
  const { clientOrder } = useSelector((state) => state.Order);
  const [pendingOrders, setPendingOrders] = useState([]);

  // console.log(clientOrder[0], "clientOrder");

  return (
    <>
      <div className="tab-pane fade h-100" id="orders">
        <div className="orders-card mt-0 h-100">
          <div className="top-sec d-flex-justify-center justify-content-between mb-4">
            <h2 className="mb-0">My Orders</h2>
          </div>
          <div className="table-bottom-brd table-responsive">
            <table className="table align-middle text-center order-table">
              <thead>
                <tr className="table-head text-nowrap">
                  <th scope="col">image</th>
                  <th scope="col">Order Id</th>
                  <th scope="col">Product Details</th>
                  <th scope="col">Price</th>
                  <th scope="col">Status</th>
                  <th scope="col">View</th>
                </tr>
              </thead>
              <tbody>
                {clientOrder &&
                  clientOrder.map((order, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          className="blur-up lazyload"
                          data-src={order.thumbnail}
                          src={order.thumbnail}
                          width={50}
                          alt={order.packSizeName}
                          title="product"
                        />
                      </td>
                      <td>
                        <Link
                          className="id"
                          style={{ textTransform: "uppercase" }}
                          to={`/order-tracking/${order._id}`}
                        >
                          #{order._id.slice(16)}
                        </Link>
                      </td>
                      <td>
                        <span className="name">
                          {order.ProductName.slice(0, 40)}
                          <br />
                        </span>
                        <span className="name">
                          {order.packSizeName.slice(0, 40)} <br />
                        </span>
                        <span className="name">
                          {order.packSizeOptionName.slice(0, 40)}
                        </span>
                      </td>
                      <td>
                        <span className="price fw-500">
                          ₹{order.Netpayable}
                        </span>
                      </td>
                      <td>
                        <span className="badge rounded-pill bg-success custom-badge">
                          {order.OrderStatusText}
                        </span>
                      </td>
                      <td>
                        <Link
                          to={`/order-tracking/${order._id}`}
                          className="view"
                        >
                          <i className="icon anm anm-eye btn-link fs-6" />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
