import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ShopBySubCategory = () => {
  const { clothing_subcategory } = useSelector((store) => store.subCategories);

  return (
    <>
      {clothing_subcategory.length > 0 && (
        <section className="section collection-slider pb-0">
          <div className="container" style={{ marginTop: "-10px" }}>
            <div className="section-header">
              {/* <p className="mb-2 mt-0" style={{color:'#666',fontFamily:"flaticon"}}>Shop by category</p> */}
              <h2
                // style={{
                //   fontFamily: "Yeseva One, serif",
                //   color: "#315999",
                //   fontSize: "1rem",
                
                // }}
                className="TitleHeading"
              >
                Popular Collection in Clothing
              </h2>
            </div>
            <div className="collection-slider-5items gp15 arwOut5 hov-arrow">
              {/* <ShopByCategorySlider /> */}
              <Swiper
                grabCursor={true}
                navigation={false}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[
                  Navigation,
                  // Pagination,
                  Mousewheel,
                  Keyboard,
                  Autoplay,
                ]}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  "@0.75": {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  "@1.00": {
                    slidesPerView: 6,
                    spaceBetween: 25,
                  },
                  "@1.50": {
                    slidesPerView: 6,
                    spaceBetween: 25,
                  },
                }}
                className="mySwiper"
                style={{ marginTop: "-30px" }}
              >
                {clothing_subcategory &&
                  clothing_subcategory.map((showData, index) => (
                    <SwiperSlide key={index}>
                      <div className="category-item zoomscal-hov">
                        <Link
                          to={`/shop/${showData.slugUrl}`}
                          className="category-link clr-none"
                        >
                          <div className="zoom-scal zoom-scal-nopb rounded-3">
                            <img
                              className="blur-up lazyload"
                              src={showData.desktopImage}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "assets/images/collection/sub-collection3.jpg";
                              }}
                              alt="Tops"
                              title="Tops"
                              width={365}
                              height={365}
                            />
                          </div>
                          <div className="details mt-3 text-center detailsSwipper">
                            <h4 className="category-title mb-0 subcattexth">
                              {showData.name.length > 15
                                ? `${showData.name.slice(0, 15)}...`
                                : showData.name}
                            </h4>
                            {/* <p className="counts">
                              {showData.product_Length} Products
                            </p> */}
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ShopBySubCategory;
