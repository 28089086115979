import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import { Flex } from "antd";
import { useNavigate, useParams } from "react-router-dom";

// import "./styles.css";

// const NewCategory = ({ products, category }) => {
const NewCategory = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [uniqueBrands, setUniqueBrands] = useState([]);
  console.log(uniqueBrands.length, "1234");

  useEffect(() => {
    const brandbycat = props.products;
    setUniqueBrands(brandbycat);
  }, [props.products]);

  // useEffect(() => {
  //   let brand_list = [];
  //   for (let index = 0; index < products.length; index++) {
  //     brand_list.push({
  //       label: String(products[index].brand),
  //       image: products[index].packSize[0].thumbnail,
  //     });
  //   }
  //   const uniqueNamesSet = new Set(brand_list.map((item) => item.label));
  //   const uniqueItems = Array.from(uniqueNamesSet).map((name) =>
  //     brand_list.find((item) => item.label === name)
  //   );
  //   setUniqueBrands(uniqueItems);
  // }, [products]);

  return (
    <>
      {uniqueBrands.length > 0 ? (
        <>
          <div className="offernyka">
            <div className="nykacard ">
              <div style={{ fontSize: "17px", fontWeight: "700",fontFamily:"Edu VIC WA NT Beginner,cursive", }}>
                Top Products by Brand
              </div>
              <div className="" style={{ margin: "10px" }}>
                <Swiper
                  slidesPerView={9}
                  // spaceBetween={3}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 5,
                      // spaceBetween: 5,
                      // spaceBetween:10,
                      spaceBetween: 14,
                    },
                    "@0.75": {
                      slidesPerView: 5,
                      spaceBetween: 15,
                    },
                    "@1.00": {
                      slidesPerView: 9,
                      spaceBetween: 10,
                    },
                    "@1.50": {
                      slidesPerView: 9,
                      spaceBetween: 13,
                    },
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                  style={{ paddingBottom: "5px" }}
                >
                  {uniqueBrands.map((indBrand, index) => (
                    <SwiperSlide
                      key={index}
                      className="forsliderSwiper"
                      onClick={() =>
                        navigate(`/${props.category}/${indBrand.name}`)
                      }
                    >
                      <div
                        className="brandNames"
                        style={{
                          width: "7rem",
                          height: "7rem",
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid #F8DE22",
                          backgroundPosition: "center",
                          color: "#000",
                          backgroundSize: "100%",
                          // fontWeight: "bold",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          overflow: "hidden",
                          backgroundImage: `url(${indBrand.mobileImage})`,
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <mark
                          className="marktag"
                          style={{
                            backgroundColor: "yellow",
                            //   paddingTop: "5px",
                            marginTop: "2rem",
                            borderRadius: "3px",
                            // position:"absolute"
                          }}
                        >
                          {indBrand.name.length > 8
                            ? `${indBrand.name.slice(0, 8)}...`
                            : indBrand.name}
                        </mark>

                        {/* <div style={{ width: "7rem", height: "7rem", objectFit: "cover", }}>
                    <img
                      src={indBrand.image}
                      style={{ borderRadius: "50%", objectFit:"fill" }}
                      className="w-60 h-60"
                      alt=""
                    />
                  </div> */}
                      </div>
                    </SwiperSlide>
                  ))}
                  {/* <SwiperSlide><img src='/assets/images/z1.png' alt="image" className="swipercategory" /></SwiperSlide>
                      
                        <SwiperSlide><img src='/assets/images/z6.png' alt="image" className="swipercategory" /></SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default NewCategory;
