import React, { useState, useEffect } from "react";
import Steper from "./Steper";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaLocationCrosshairs } from "react-icons/fa6";
import {
  add_Address,
  set_Current_address,
  update_Address,
} from "../../../redux/athentication/Athentication";
import {
  Button,
  Modal,
  Space,
  Flex,
  Form,
  Select,
  Col,
  Divider,
  Row,
  Typography,
  Radio,
} from "antd";
import { TextField } from "@mui/material";
import axios from "axios";

const AddAddress = ({ close_add_address }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginData, current_address, user_address } = useSelector(
    (state) => state.Athentication
  );

  const [info_model, setinfo_model] = useState(false);

  const [Mobile, setMobile] = useState("");
  const [MobileError, setMobileError] = useState("");
  const [HNo, setHNo] = useState("");
  const [HNoerror, setHNoError] = useState("");
  const [Area, setArea] = useState("");
  const [Areaerror, setAreaError] = useState("");
  const [sub_locality, setsub_locality] = useState("");
  const [sub_localityerror, setsub_localityError] = useState("");
  const [locality, setlocality] = useState("");
  const [localityerror, setlocalityError] = useState("");
  const [LandMark, setLandMark] = useState("");
  const [LandMarkerror, setLandMarkError] = useState("");
  const [City, setCity] = useState("");
  const [Cityerror, setCityError] = useState("");
  const [State, setState] = useState("");
  const [Stateerror, setStateError] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Pincodeerror, setPincodeError] = useState("");
  const [Type, setType] = useState("Home");

  const [btnLoading, setBtnLoading] = useState(false);

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    address: {
      city: "",
      city_district: "",
      country: "",
      county: "",
      neighbourhood: "",
      postcode: "",
      state: "",
      state_district: "",
      suburb: "",
    },
    error: null,
  });

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation((prevState) => ({
            ...prevState,
            latitude,
            longitude,
            error: null,
          }));
          getAddress(latitude, longitude);
        },
        (error) => {
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          });
        }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  };

  const getAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
      );
      // const address = {...response.data.address}
      // || 'Address not found';
      const address = {
        city: response.data.address.city,
        city_district: response.data.address.city_district,
        country: response.data.address.country,
        neighbourhood: response.data.address.neighbourhood,
        postcode: response.data.address.postcode,
        state: response.data.address.state,
        state_district: response.data.address.state_district,
        suburb: response.data.address.suburb,
        county: response.data.address.county,
      };
      setLocation((prevState) => ({
        ...prevState,
        address,
      }));
    } catch (error) {
      setLocation((prevState) => ({
        ...prevState,
        address: "Unable to fetch address",
        error: error.message,
      }));
    }
  };

  useEffect(() => {
    setHNo(location.address.neighbourhood);
    setArea(location.address.suburb);
    setCity(location.address.city);
    setState(location.address.state);
    setPincode(location.address.postcode);
    setlocality(location.address.county);
    setsub_locality(location.address.city_district);
  }, [location]);

  useEffect(() => {
    if (btnLoading === true) {
      if (Mobile.length !== 10) {
        setMobileError("Required");
      } else {
        setMobileError("");
      }

      if (HNo === "") {
        setHNoError("Required");
      } else {
        setHNoError("");
      }
      if (Area === "") {
        setAreaError("Required");
      } else {
        setAreaError("");
      }
      if (sub_locality === "") {
        setsub_localityError("Required");
      } else {
        setsub_localityError("");
      }
      if (locality === "") {
        setlocalityError("Required");
      } else {
        setlocalityError("");
      }

      if (City === "") {
        setCityError("Required");
      } else {
        setCityError("");
      }
      if (State === "") {
        setStateError("Required");
      } else {
        setStateError("");
      }
      if (Pincode.length !== 6) {
        setPincodeError("Required");
      } else {
        setPincodeError("");
      }
    }
  }, [
    btnLoading,
    Mobile,
    HNo,
    Area,
    sub_locality,
    locality,
    City,
    State,
    Pincode,
  ]);

  const add_address = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (Mobile.length !== 10) {
      setMobileError("Required");
    } else {
      setMobileError("");
    }

    if (HNo === "") {
      setHNoError("Required");
    } else {
      setHNoError("");
    }
    if (Area === "") {
      setAreaError("Required");
    } else {
      setAreaError("");
    }
    if (sub_locality === "") {
      setsub_localityError("Required");
    } else {
      setsub_localityError("");
    }
    if (locality === "") {
      setlocalityError("Required");
    } else {
      setlocalityError("");
    }

    if (City === "") {
      setCityError("Required");
    } else {
      setCityError("");
    }
    if (State === "") {
      setStateError("Required");
    } else {
      setStateError("");
    }
    if (Pincode.length !== 6) {
      setPincodeError("Required");
    } else {
      setPincodeError("");
    }

    if (
      Pincode.length === 6 &&
      Mobile.length === 10 &&
      HNo !== "" &&
      Area !== "" &&
      locality !== "" &&
      City !== "" &&
      State !== "" &&
      sub_locality !== ""
    ) {
      const form_data = {
        Name: loginData.Name,
        Mobile: Mobile,
        Alternative_Mobile: loginData.Mobile,
        ClientId: loginData._id,
        HNo: HNo,
        Area: Area,
        sub_locality: sub_locality,
        locality: locality,
        LandMark: LandMark,
        City: City,
        State: State,
        Pincode: Pincode,
        Type: Type,
        LandMark: LandMark,
        prime: true,
      };

      const add_address_post = await dispatch(add_Address(form_data));
      console.log(form_data,"form_data123456")

      if (add_address_post.payload.success) {
        const client = add_address_post.payload.client;
        const data_address = client.Addresses;

        await dispatch(update_Address(data_address));
        close_add_address();
        setMobile("");
        setHNo("");
        setArea("");
        setsub_locality("");
        setlocality("");
        setLandMark("");
        setCity("");
        setState("");
        setPincode("");

        setBtnLoading(false);
        setinfo_model(false);
      }
    }
  };

  const closeclick = () => {
    setMobile("");
    setHNo("");
    setArea("");
    setsub_locality("");
    setlocality("");
    setLandMark("");
    setCity("");
    setState("");
    setPincode("");
    setLocation({
      latitude: null,
      longitude: null,
      address: {
        city: "",
        city_district: "",
        country: "",
        county: "",
        neighbourhood: "",
        postcode: "",
        state: "",
        state_district: "",
        suburb: "",
      },
      error: null,
    });
    setinfo_model(false);
    setBtnLoading(false);
  };
  return (
    <>
      <div>
        <div className="input_box_mi">
          <TextField
            label="Mobile"
            className="textfield"
            type="text"
            id="text"
            style={{ width: "100%", marginTop: "2vh" }}
            error={MobileError !== "" ? true : false}
            helperText={MobileError}
            value={Mobile}
            onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
            inputProps={{
              minLength: 10,
              maxLength: 10,
            }}
            autocomplete="off"
          />
        </div>
        <div className="input_box_mi">
          <TextField
            label="HNo"
            className="textfield"
            type="text"
            id="text"
            style={{ width: "100%", marginTop: "2vh" }}
            error={HNoerror !== "" ? true : false}
            helperText={HNoerror}
            value={HNo}
            onChange={(e) => setHNo(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="input_box_mi">
          <TextField
            label="Area"
            className="textfield"
            type="text"
            id="text"
            style={{ width: "100%", marginTop: "2vh" }}
            error={Areaerror !== "" ? true : false}
            helperText={Areaerror}
            value={Area}
            onChange={(e) => setArea(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="input_box_mi">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "2vh",
            }}
          >
            <TextField
              label="Sub locality"
              className="textfield"
              type="text"
              id="text"
              style={{ width: "49%" }}
              error={sub_localityerror !== "" ? true : false}
              helperText={sub_localityerror}
              value={sub_locality}
              onChange={(e) => setsub_locality(e.target.value)}
              autocomplete="off"
            />
            <TextField
              label="locality"
              className="textfield"
              type="text"
              id="text"
              style={{ width: "49%" }}
              error={localityerror !== "" ? true : false}
              helperText={localityerror}
              value={locality}
              onChange={(e) => setlocality(e.target.value)}
              autocomplete="off"
            />
          </div>
        </div>
        <div className="input_box_mi">
          <TextField
            label="LandMark"
            className="textfield"
            type="text"
            id="text"
            style={{ width: "100%", marginTop: "2vh" }}
            error={LandMarkerror !== "" ? true : false}
            helperText={LandMarkerror}
            value={LandMark}
            onChange={(e) => setLandMark(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="input_box_mi">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "2vh",
            }}
          >
            <TextField
              label="City"
              className="textfield"
              type="text"
              id="text"
              style={{ width: "49%" }}
              error={Cityerror !== "" ? true : false}
              helperText={Cityerror}
              value={City}
              onChange={(e) => setCity(e.target.value)}
              autocomplete="off"
            />
            <TextField
              label="State"
              className="textfield"
              type="text"
              id="text"
              style={{ width: "49%" }}
              error={Stateerror !== "" ? true : false}
              helperText={Stateerror}
              value={State}
              onChange={(e) => setState(e.target.value)}
              autocomplete="off"
            />
          </div>
        </div>
        <div
          className="input_box_mi"
          style={{
            marginTop: "2vh",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              label="Pincode"
              className="textfield"
              type="text"
              id="text"
              error={Pincodeerror !== "" ? true : false}
              helperText={Pincodeerror}
              onChange={(e) => setPincode(e.target.value.replace(/\D/g, ""))}
              value={Pincode}
              inputProps={{
                minLength: 6,
                maxLength: 6,
              }}
              autocomplete="off"
              style={{ width: "49%" }}
            />
            <div
              style={{
                width: "49%",
                height: "6vh",
                backgroundColor: "#f1c40f",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                padding: "1vh 1.5vh",
                color: "#fff",
                fontWeight: "600",
                fontSize: "1rem",
              }}
              onClick={() => getLocation()}
            >
              <FaLocationCrosshairs size={18} /> <span>Use my location</span>
            </div>
          </div>
        </div>
        <div
          className="buttondiv_drower"
          style={{
            marginTop: "1vh",
          }}
        >
          <div className="active_button_drow" onClick={(e) => add_address(e)}>
            Save Address
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAddress;
