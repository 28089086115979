// export default RelatedProduct

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ElectronicSingleProduct from "../../component/product/ElectronicSingleProduct";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const RelatedProduct = (props) => {
  const { electronicHotProduct } = useSelector((store) => store.product);
  const [relatedProducts, setRelatedProducts] = useState([])
  console.log(props,'props');
  
  useEffect(() => {
    const getRelatedProduct = async () => {
      const url = `${Baseurl}/api/v1/product/all-products-by-sub-category-limit/${props.products}`;
      const res = await axios.get(url);
      console.log(res.data, "res.data");
      setRelatedProducts(res.data.products)
    };
    getRelatedProduct();
  }, []);
  const navigate = useNavigate();

  return (
    <>
      {relatedProducts.length > 0 && (
        <section className="section product-banner-slider pt-0">
          <div className="container">
            <div
              className="section-header style2 d-flex-center justify-content-sm-between"
              style={{ marginTop: "-15px" }}
            >
              <div className="section-header-left text-start w-100">
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Ga Maamli,sans-serif",
                    color: "#315999",
                    fontSize: "26px",
                  }}
                >
                  Related Product
                </h2>
                {/* <p  style={{display:'flex',justifyContent:"center",alignItems:"center"}}>Shop our new arrivals in Electronics</p> */}
              </div>
              {/* <div className="section-header-right text-start text-sm-end mt-3 mt-sm-0">
              <a href="shop-left-sidebar.html" className="btn btn-primary">
                View More <i className="icon anm anm-arw-right ms-2" />
              </a>
            </div> */}
            </div>
            <div className="row">
              <div className="grid-products product-slider-3items gp15 arwOut5 hov-arrow circle-arrow arrowlr-0">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    "@0.75": {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    "@1.00": {
                      slidesPerView: 5,
                      spaceBetween: 25,
                    },
                    "@1.50": {
                      slidesPerView: 5,
                      spaceBetween: 25,
                    },
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                  style={{ marginTop: "-30px" }}
                >
                  {relatedProducts &&
                    relatedProducts.map((product, index) => (
                      <SwiperSlide key={index}>
                        <ElectronicSingleProduct product={product} />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default RelatedProduct;
