import React from "react";

const Clothing_Supercategory = ({ productifo }) => {
  return (
    <>
      <tbody>
        {productifo.clothing.DesignDetails !== "" && (
          <tr>
            <th>Design Details</th>
            <td>{productifo.clothing.DesignDetails}</td>
          </tr>
        )}
        {productifo.clothing.topDesign !== "" && (
          <tr>
            <th>top Design</th>
            <td>{productifo.clothing.topDesign}</td>
          </tr>
        )}
        {productifo.clothing.bottomDesign !== "" && (
          <tr>
            <th>bottom Design</th>
            <td>{productifo.clothing.bottomDesign}</td>
          </tr>
        )}
        {productifo.clothing.SizeAndFit !== "" && (
          <tr>
            <th>Size And Fit</th>
            <td>{productifo.clothing.SizeAndFit}</td>
          </tr>
        )}
        {productifo.clothing.MaterialAndCare !== "" && (
          <tr>
            <th>Material And Care</th>
            <td>{productifo.clothing.MaterialAndCare}</td>
          </tr>
        )}
        {productifo.clothing.SleeveLength !== "" && (
          <tr>
            <th>Sleeve Length</th>
            <td>{productifo.clothing.SleeveLength}</td>
          </tr>
        )}
        {productifo.clothing.SleeveStyling !== "" && (
          <tr>
            <th>Sleeve Styling</th>
            <td>{productifo.clothing.SleeveStyling}</td>
          </tr>
        )}
        {productifo.clothing.Shape !== "" && (
          <tr>
            <th>Shape</th>
            <td>{productifo.clothing.Shape}</td>
          </tr>
        )}
        {productifo.clothing.Neck !== "" && (
          <tr>
            <th>Neck</th>
            <td>{productifo.clothing.Neck}</td>
          </tr>
        )}
        {productifo.clothing.PrintorPatternType !== "" && (
          <tr>
            <th>Printor and Pattern Type</th>
            <td>{productifo.clothing.PrintorPatternType}</td>
          </tr>
        )}
        {productifo.clothing.DesignStyling !== "" && (
          <tr>
            <th>Design Styling</th>
            <td>{productifo.clothing.DesignStyling}</td>
          </tr>
        )}
        {productifo.clothing.Length !== "" && (
          <tr>
            <th>Length</th>
            <td>{productifo.clothing.Length}</td>
          </tr>
        )}
        {productifo.clothing.Hemline !== "" && (
          <tr>
            <th>Hemline</th>
            <td>{productifo.clothing.Hemline}</td>
          </tr>
        )}
        {productifo.clothing.WeavePattern !== "" && (
          <tr>
            <th>Weave Pattern</th>
            <td>{productifo.clothing.WeavePattern}</td>
          </tr>
        )}
        {productifo.clothing.WeaveType !== "" && (
          <tr>
            <th>Weave Type</th>
            <td>{productifo.clothing.WeaveType}</td>
          </tr>
        )}
        {productifo.clothing.Occasion !== "" && (
          <tr>
            <th>Occasion</th>
            <td>{productifo.clothing.Occasion}</td>
          </tr>
        )}
        {productifo.clothing.CompleteTheLook !== "" && (
          <tr>
            <th>Complete The Look</th>
            <td>{productifo.clothing.CompleteTheLook}</td>
          </tr>
        )}
        {productifo.clothing.Type !== "" && (
          <tr>
            <th>Type</th>
            <td>{productifo.clothing.Type}</td>
          </tr>
        )}
        {productifo.clothing.Transparency !== "" && (
          <tr>
            <th>Transparency</th>
            <td>{productifo.clothing.Transparency}</td>
          </tr>
        )}
        {productifo.clothing.MainTrend !== "" && (
          <tr>
            <th>MainTrend</th>
            <td>{productifo.clothing.MainTrend}</td>
          </tr>
        )}
        {productifo.clothing.Ornamentation !== "" && (
          <tr>
            <th>Ornamentation</th>
            <td>{productifo.clothing.Ornamentation}</td>
          </tr>
        )}
        {productifo.clothing.Border !== "" && (
          <tr>
            <th>Border</th>
            <td>{productifo.clothing.Border}</td>
          </tr>
        )}
        {productifo.clothing.DupattaBorder !== "" && (
          <tr>
            <th>Dupatta Border</th>
            <td>{productifo.clothing.DupattaBorder}</td>
          </tr>
        )}
        {productifo.clothing.blouseBorder !== "" && (
          <tr>
            <th>blouse Border</th>
            <td>{productifo.clothing.blouseBorder}</td>
          </tr>
        )}
        {productifo.clothing.Blouse !== "" && (
          <tr>
            <th>Blouse</th>
            <td>{productifo.clothing.Blouse}</td>
          </tr>
        )}
        {productifo.clothing.Fabric !== "" && (
          <tr>
            <th>Fabric</th>
            <td>{productifo.clothing.Fabric}</td>
          </tr>
        )}
        {productifo.clothing.BlouseFabric !== "" && (
          <tr>
            <th>Blouse Fabric</th>
            <td>{productifo.clothing.BlouseFabric}</td>
          </tr>
        )}
        {productifo.clothing.dupattaFabric !== "" && (
          <tr>
            <th>dupatta Fabric</th>
            <td>{productifo.clothing.dupattaFabric}</td>
          </tr>
        )}
        {productifo.clothing.BottomType !== "" && (
          <tr>
            <th>Bottom Type</th>
            <td>{productifo.clothing.BottomType}</td>
          </tr>
        )}
        {productifo.clothing.BottomPattern !== "" && (
          <tr>
            <th>Bottom Pattern</th>
            <td>{productifo.clothing.BottomPattern}</td>
          </tr>
        )}
        {productifo.clothing.TopPattern !== "" && (
          <tr>
            <th>Top Pattern</th>
            <td>{productifo.clothing.TopPattern}</td>
          </tr>
        )}
        {productifo.clothing.Dupatta !== "" && (
          <tr>
            <th>Dupatta</th>
            <td>{productifo.clothing.Dupatta}</td>
          </tr>
        )}
        {productifo.clothing.DupattaPattern !== "" && (
          <tr>
            <th>Dupatta Pattern</th>
            <td>{productifo.clothing.DupattaPattern}</td>
          </tr>
        )}
        {productifo.clothing.topClosure !== "" && (
          <tr>
            <th>top Closure</th>
            <td>{productifo.clothing.topClosure}</td>
          </tr>
        )}
        {productifo.clothing.DesignDetails !== "" && (
          <tr>
            <th>DesignDetails</th>
            <td>{productifo.clothing.DesignDetails}</td>
          </tr>
        )}
        {productifo.clothing.washcare !== "" && (
          <tr>
            <th>washcare</th>
            <td>{productifo.clothing.washcare}</td>
          </tr>
        )}
        {productifo.clothing.fit !== "" && (
          <tr>
            <th>fit</th>
            <td>{productifo.clothing.fit}</td>
          </tr>
        )}
        {productifo.clothing.patternCoverage !== "" && (
          <tr>
            <th>pattern Coverage</th>
            <td>{productifo.clothing.patternCoverage}</td>
          </tr>
        )}
        {productifo.clothing.multipackset !== "" && (
          <tr>
            <th>multi packset</th>
            <td>{productifo.clothing.multipackset}</td>
          </tr>
        )}
        {productifo.clothing.sustainable !== "" && (
          <tr>
            <th>sustainable</th>
            <td>{productifo.clothing.sustainable}</td>
          </tr>
        )}
        {productifo.clothing.Collar !== "" && (
          <tr>
            <th>Collar</th>
            <td>{productifo.clothing.Collar}</td>
          </tr>
        )}
        {productifo.clothing.NumberofPockets !== "" && (
          <tr>
            <th>Number of Pockets</th>
            <td>{productifo.clothing.NumberofPockets}</td>
          </tr>
        )}
        {productifo.clothing.SlitDetail !== "" && (
          <tr>
            <th>Slit Detail</th>
            <td>{productifo.clothing.SlitDetail}</td>
          </tr>
        )}
        {productifo.clothing.distress !== "" && (
          <tr>
            <th>distress</th>
            <td>{productifo.clothing.distress}</td>
          </tr>
        )}
        {productifo.clothing.waistRise !== "" && (
          <tr>
            <th>waistRise</th>
            <td>{productifo.clothing.waistRise}</td>
          </tr>
        )}
        {productifo.clothing.fade !== "" && (
          <tr>
            <th>fade</th>
            <td>{productifo.clothing.fade}</td>
          </tr>
        )}
        {productifo.clothing.shade !== "" && (
          <tr>
            <th>shade</th>
            <td>{productifo.clothing.shade}</td>
          </tr>
        )}
        {productifo.clothing.strech !== "" && (
          <tr>
            <th>strech</th>
            <td>{productifo.clothing.strech}</td>
          </tr>
        )}
        {productifo.clothing.reversible !== "" && (
          <tr>
            <th>reversible</th>
            <td>{productifo.clothing.reversible}</td>
          </tr>
        )}
        {productifo.clothing.waistband !== "" && (
          <tr>
            <th>waistband</th>
            <td>{productifo.clothing.waistband}</td>
          </tr>
        )}
        {productifo.clothing.BottomFabric !== "" && (
          <tr>
            <th>Bottom Fabric</th>
            <td>{productifo.clothing.BottomFabric}</td>
          </tr>
        )}
        {productifo.clothing.BottomClosure !== "" && (
          <tr>
            <th>Bottom Closure</th>
            <td>{productifo.clothing.BottomClosure}</td>
          </tr>
        )}
        {productifo.clothing.topStitch !== "" && (
          <tr>
            <th>top Stitch</th>
            <td>{productifo.clothing.topStitch}</td>
          </tr>
        )}
        {productifo.clothing.bottomStitch !== "" && (
          <tr>
            <th>bottom Stitch</th>
            <td>{productifo.clothing.bottomStitch}</td>
          </tr>
        )}
        {productifo.clothing.Placket !== "" && (
          <tr>
            <th>Placket</th>
            <td>{productifo.clothing.Placket}</td>
          </tr>
        )}
        {productifo.clothing.PlacketLength !== "" && (
          <tr>
            <th>Placket Length</th>
            <td>{productifo.clothing.PlacketLength}</td>
          </tr>
        )}
        {productifo.clothing.Placket_type !== "" && (
          <tr>
            <th>Placket type</th>
            <td>{productifo.clothing.Placket_type}</td>
          </tr>
        )}
        {productifo.clothing.cuff !== "" && (
          <tr>
            <th>cuff</th>
            <td>{productifo.clothing.cuff}</td>
          </tr>
        )}
        {productifo.clothing.surfaces_styling !== "" && (
          <tr>
            <th>surfaces styling</th>
            <td>{productifo.clothing.surfaces_styling}</td>
          </tr>
        )}
        {productifo.clothing.features !== "" && (
          <tr>
            <th>features</th>
            <td>{productifo.clothing.features}</td>
          </tr>
        )}
        {productifo.clothing.border_Fabric !== "" && (
          <tr>
            <th>border_Fabric</th>
            <td>{productifo.clothing.border_Fabric}</td>
          </tr>
        )}
        {productifo.clothing.tyepe_of_distress !== "" && (
          <tr>
            <th>tyepe_of_distress</th>
            <td>{productifo.clothing.tyepe_of_distress}</td>
          </tr>
        )}
        {productifo.clothing.fly_Type !== "" && (
          <tr>
            <th>fly_Type</th>
            <td>{productifo.clothing.fly_Type}</td>
          </tr>
        )}
        {productifo.clothing.assorted !== "" && (
          <tr>
            <th>assorted</th>
            <td>{productifo.clothing.assorted}</td>
          </tr>
        )}
        {productifo.clothing.knighr_or_woven !== "" && (
          <tr>
            <th>knighr_or_woven</th>
            <td>{productifo.clothing.knighr_or_woven}</td>
          </tr>
        )}
        {productifo.clothing.bottom_length !== "" && (
          <tr>
            <th>bottom_length</th>
            <td>{productifo.clothing.bottom_length}</td>
          </tr>
        )}
        {productifo.clothing.bottom_border_fabric !== "" && (
          <tr>
            <th>bottom_border_fabric</th>
            <td>{productifo.clothing.bottom_border_fabric}</td>
          </tr>
        )}
        {productifo.clothing.type_of_pleat !== "" && (
          <tr>
            <th>type_of_pleat</th>
            <td>{productifo.clothing.type_of_pleat}</td>
          </tr>
        )}
        {productifo.clothing.front_Style !== "" && (
          <tr>
            <th>front_Style</th>
            <td>{productifo.clothing.front_Style}</td>
          </tr>
        )}
        {productifo.clothing.padding !== "" && (
          <tr>
            <th>padding</th>
            <td>{productifo.clothing.padding}</td>
          </tr>
        )}
        {productifo.clothing.seam !== "" && (
          <tr>
            <th>seam</th>
            <td>{productifo.clothing.seam}</td>
          </tr>
        )}
        {productifo.clothing.colourfamily !== "" && (
          <tr>
            <th>colourfamily</th>
            <td>{productifo.clothing.colourfamily}</td>
          </tr>
        )}
        {productifo.clothing.hexa_Code !== "" && (
          <tr>
            <th>hexa_Code</th>
            <td>{productifo.clothing.hexa_Code}</td>
          </tr>
        )}
        {productifo.clothing.finish !== "" && (
          <tr>
            <th>finish</th>
            <td>{productifo.clothing.finish}</td>
          </tr>
        )}
        {productifo.clothing.formulation !== "" && (
          <tr>
            <th>formulation</th>
            <td>{productifo.clothing.formulation}</td>
          </tr>
        )}
        {productifo.clothing.preferance !== "" && (
          <tr>
            <th>preferance</th>
            <td>{productifo.clothing.preferance}</td>
          </tr>
        )}
        {productifo.clothing.concern !== "" && (
          <tr>
            <th>concern</th>
            <td>{productifo.clothing.concern}</td>
          </tr>
        )}
        {productifo.clothing.key_ingredient !== "" && (
          <tr>
            <th>key_ingredient</th>
            <td>{productifo.clothing.key_ingredient}</td>
          </tr>
        )}
        {productifo.clothing.skin_type !== "" && (
          <tr>
            <th>skin_type</th>
            <td>{productifo.clothing.skin_type}</td>
          </tr>
        )}
        {productifo.clothing.stone_Type !== "" && (
          <tr>
            <th>stone_Type</th>
            <td>{productifo.clothing.stone_Type}</td>
          </tr>
        )}
        {productifo.clothing.puriety !== "" && (
          <tr>
            <th>puriety</th>
            <td>{productifo.clothing.puriety}</td>
          </tr>
        )}

      </tbody>
    </>
  );
};

export default Clothing_Supercategory;
