import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const HomeSmallBanner = () => {
  const navigate = useNavigate();

  const { clothingthreeSlider } = useSelector((store) => store.slider);

  const [shoe1stImage, setshoe1stImage] = useState("");
  const [shoe2stImage, setshoe2stImage] = useState("");
  const [shoe3stImage, setshoe3stImage] = useState("");

  useEffect(() => {
    if (clothingthreeSlider.length > 0) {
      const find1stImage = clothingthreeSlider.find(
        (data) => data.position1 === true
      );
      const find2stImage = clothingthreeSlider.find(
        (data) => data.position2 === true
      );
      const find3stImage = clothingthreeSlider.find(
        (data) => data.position3 === true
      );

      setshoe1stImage(find1stImage);
      setshoe2stImage(find2stImage);
      setshoe3stImage(find3stImage);
    }
  }, [clothingthreeSlider]);

  return (
    <>
      <section className="section collection-banners pb-0 bannercollection">
        <div className="container">
          <div className="collection-banner-grid">
            <div className="row sp-row">
              {shoe1stImage !== "" && (
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 collection-banner-item">
                  <div
                    className="collection-item sp-col"
                    onClick={() =>
                      navigate(`/category/${shoe1stImage.categoryUrl}`)
                    }
                  >
                    <a to="" className="zoom-scal">
                      <div className="img">
                        <img
                          className="blur-up lazyload "
                          data-src={shoe1stImage.desktopImage}
                          src={shoe1stImage.desktopImage}
                          alt="Women Wear"
                          title="Women Wear"
                          width={645}
                          height={723}
                          style={{height:"500px"}}
                        />
                      </div>
                      <div className="details middle-right">
                        <div className="inner">
                          <p className="mb-2">{shoe1stImage.text}</p>
                          <h3 className="title">
                            {shoe1stImage.category} Wear
                          </h3>
                          <span className="btn btn-outline-secondary btn-md">
                            Shop Now
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )}
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 collection-banner-item">
                {shoe2stImage !== "" && (
                  <div
                    className="collection-item sp-col"
                    onClick={() =>
                      navigate(`/category/${shoe2stImage.categoryUrl}`)
                    }
                  >
                    <a to="" className="zoom-scal">
                      <div className="img">
                        <img
                          className="blur-up lazyload"
                          data-src={shoe2stImage.desktopImage}
                          src={shoe2stImage.desktopImage}
                          alt="Mens Wear"
                          title="Mens Wear"
                          width={645}
                          height={344}
                          style={{height:"240px"}}
                        />
                      </div>
                      <div className="details middle-left">
                        <div className="inner">
                          <p className="mb-2">{shoe2stImage.text}</p>
                          <h3 className="title">
                            {shoe2stImage.category} Wear
                          </h3>
                          <span className="btn btn-outline-secondary btn-md">
                            Shop Now
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                )}
                {shoe3stImage !== "" && (
                  <div
                    className="collection-item sp-col"
                    onClick={() =>
                      navigate(`/category/${shoe3stImage.categoryUrl}`)
                    }
                  >
                    <a to="" className="zoom-scal">
                      <div className="img">
                        <img
                          className="blur-up lazyload"
                          data-src={shoe3stImage.desktopImage}
                          src={shoe3stImage.desktopImage}
                          alt="Kids Wear"
                          title="Kids Wear"
                          width={645}
                          height={349}
                          style={{height:"240px"}}
                        />
                      </div>
                      <div className="details middle-right">
                        <div className="inner">
                          <p className="mb-2">{shoe3stImage.text}</p>
                          <h3 className="title">
                            {shoe3stImage.category} Wear
                          </h3>
                          <span className="btn btn-outline-secondary btn-md">
                            Shop Now
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeSmallBanner;
