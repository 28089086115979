import React from "react";
import Clothing_Supercategory from "./components/Clothing_Supercategory";
import Electronic_Supercategory from "./components/Electronic_Supercategory";

const Product_Additional_Info = ({ productifo }) => {
  const renderSwitch = (param) => {
    switch (param) {
      case "Clothing": {
        return (
          <>
            <Clothing_Supercategory productifo={productifo} />
          </>
        );
      }
      //   case "Beauty": {
      //     return (
      //       <>
      //         <BeautySupercat
      //            productifo={productifo}
      //         />
      //       </>
      //     );
      //   }
      case "Electronics": {
        return (
          <>
            <Electronic_Supercategory productifo={productifo} />
          </>
        );
      }
      //   case "Grocery": {
      //     return (
      //       <>
      //         <GrocerySupercat
      //            productifo={productifo}
      //         />
      //       </>
      //     );
      //   }
      //   case "Medicines": {
      //     return (
      //       <>
      //         <MedicienSupercat
      //            productifo={productifo}
      //         />
      //       </>
      //     );
      //   }
      //   case "Home and furniture": {
      //     return (
      //       <>
      //         <HomeneedsSupercategory
      //            productifo={productifo}
      //         />
      //       </>
      //     );
      //   }
      //   case "Footwear": {
      //     return (
      //       <>
      //         <FootwearSupercategory
      //            productifo={productifo}
      //         />
      //       </>
      //     );
      //   }
      //   case "Toys & games": {
      //     return (
      //       <>
      //         <GamesandToysSuperCategory
      //            productifo={productifo}
      //         />
      //       </>
      //     );
      //   }

      default:
        return <>{/* <ClothingSupercat /> */}</>;
    }
  };

  return <>{renderSwitch(productifo.superCategory)}</>;
};

export default Product_Additional_Info;
