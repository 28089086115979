import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MobileCategogy = () => {
    const navigate =useNavigate()
  const { categorytotal } = useSelector((store) => store.category);
  // useEffect(()=>{
  //     if(categorytotal.length > 0){

  //     }
  // },[])
  const handleClick =(slugUrl)=>{
    navigate(`/category/${slugUrl}`)
  }
  console.log(categorytotal,"categorytotal")
  return (
    <>
    <div className="category_heading" style={{fontWeight:"700",marginTop:"90px"}}>Popular Categories on EWShopping</div>
      <div className="categorymain">
        {categorytotal.map((indCat, index) => (
          <div className="Categorydiv" key={index} onClick={()=>handleClick(indCat.slugUrl)}>
            <div className="category_img">
              <img src={indCat.desktopImage} alt="" />
            </div>
            <p>{indCat.name}</p>
          </div>
        ))}
      </div>
      <div style={{marginBottom:"50px"}}></div>
    </>
  );
};

export default MobileCategogy;
