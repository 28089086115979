import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BeautySubcategory = () => {
  const { beauty_subcategory } = useSelector((store) => store.subCategories);
  return (
    <>
      {beauty_subcategory.length > 0 && (
        <section className="section collection-slider pb-0">
          <div className="container">
            <div className="section-header" style={{ marginTop: "-20px" }}>
              {/* <p className="mb-2 mt-0">Shop by category</p> */}
              <h2
                // style={{
                //   fontFamily: "Ga Maamli,sans-serif",
                //   color: "#315999",
                //   fontSize: "26px",
                // }}
                className="TitleHeading"
              >
                Popular Collections in Beauty
              </h2>
            </div>
            <div className="collection-slider-5items gp15 arwOut5 hov-arrow">
              {/* <ShopByCategorySlider /> */}
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  "@0.75": {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  "@1.00": {
                    slidesPerView: 6,
                    spaceBetween: 25,
                  },
                  "@1.50": {
                    slidesPerView: 6,
                    spaceBetween: 25,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
                style={{ marginTop: "-26px" }}
              >
                {beauty_subcategory &&
                  beauty_subcategory.map((showData, index) => (
                    <SwiperSlide key={index}>
                      <div className="category-item zoomscal-hov">
                        <Link
                          to={`/shop/${showData.slugUrl}`}
                          className="category-link clr-none"
                        >
                          <div className="zoom-scal zoom-scal-nopb rounded-3">
                            <img
                              className="blur-up lazyload"
                              src={showData.desktopImage}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "/assets/images/collection/sub-collection3.jpg";
                              }}
                              alt="Tops"
                              title="Tops"
                              width={365}
                              height={365}
                            />
                          </div>
                          <div className="details mt-3 text-center detailsSwipper">
                            <h4 className="category-title mb-0">
                              {showData.name}
                            </h4>
                            {/* <p className="counts">
                              {showData.product_Length} Products
                            </p> */}
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BeautySubcategory;
