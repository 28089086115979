import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl, Baseurl2 } from "../../config/BaseUrl";

const initialState = {
  totalClientOrder: localStorage.getItem("totalClientOrder")
    ? JSON.parse(localStorage.getItem("totalClientOrder"))
    : [],
  clientOrder: localStorage.getItem("clientOrder")
    ? JSON.parse(localStorage.getItem("clientOrder"))
    : [],
  currentOrder: localStorage.getItem("currentOrder")
    ? JSON.parse(localStorage.getItem("currentOrder"))
    : [],
  paymentUpdateOrder: localStorage.getItem("paymentUpdateOrder")
    ? JSON.parse(localStorage.getItem("paymentUpdateOrder"))
    : "",
  // productOrder: localStorage.getItem("productOrder")
  //   ? JSON.parse(localStorage.getItem("productOrder")).sort((a, b) =>
  //       a.name > b.name ? 1 : -1
  //     )
  //   : [],
  // productShow: localStorage.getItem("productOrder")
  //   ? JSON.parse(localStorage.getItem("productOrder")).filter(
  //       (data) => data.status === true
  //     )
  //   : [],
  // isProductAvailable: localStorage.getItem("productOrder") ? true : false,
  ordersLoading: true,
  isordersLoading: true,
};

export const newOrderSMS = createAsyncThunk(
  "order/newOrder",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/order/sendsms`;

      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Order Failed");
    }
  }
);

export const newOrder = createAsyncThunk(
  "order/newOrder",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/order/new`;

      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(" grocer order not create");
    }
  }
);

export const getOrderbyClId = createAsyncThunk(
  "order/getOrderbyClId",
  async (clientid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/tenOrderbyclient/${clientid}`;
      console.log(url,'url');
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Pincode Not Found");
    }
  }
);

export const getTotalOrderByClId = createAsyncThunk(
  "order/totalOrder",
  async (clientid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/orderbyclientid/${clientid}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Orders Not Found");
    }
  }
);

export const gerenatenewTokrnId = createAsyncThunk(
  "order/gerenatenewTokrnId",
  async (orderId = 0, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/order/generatenewtoken/${orderId}`;

      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Pincode Not Found");
    }
  }
);

export const updateOrder = createAsyncThunk(
  "Order/updateOrder",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/order/${formData.orderid}`;

      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("order Not Updated");
    }
  }
);

const OrderSlice = createSlice({
  name: "Order",
  initialState,
  reducers: {
    clearOrder(state, action) {
      state.clientOrder = [];
      state.currentOrder = "";
      localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
      localStorage.setItem(" currentOrder", JSON.stringify(state.currentOrder));
    },
    setpaymentUpdateOrder(state, action) {
      state.paymentUpdateOrder = action.payload.order;
      localStorage.setItem(
        " paymentUpdateOrder",
        JSON.stringify(state.paymentUpdateOrder)
      );
    },
    setpaymentUpdateOrderClear(state, action) {
      state.paymentUpdateOrder = "";
      localStorage.setItem(
        " paymentUpdateOrder",
        JSON.stringify(state.paymentUpdateOrder)
      );
    },
    setcurrentOrder(state, action) {
      state.currentOrder = action.payload;
      localStorage.setItem(" currentOrder", JSON.stringify(state.currentOrder));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newOrder.pending, (state) => {
        state.ordersLoading = true;
      })
      .addCase(newOrder.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.clientOrder = [action.payload.order, ...state.clientOrder];
        }
        localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
        state.ordersLoading = false;
      })
      .addCase(newOrder.rejected, (state) => {
        state.ordersLoading = true;
      });
    builder
      .addCase(getOrderbyClId.pending, (state) => {
        state.isordersLoading = true;
      })
      .addCase(getOrderbyClId.fulfilled, (state, action) => {
        state.clientOrder = action.payload.order;
        localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
        state.isordersLoading = false;
      })
      .addCase(getOrderbyClId.rejected, (state) => {
        state.isordersLoading = true;
      })
      .addCase(getTotalOrderByClId.pending, (state) => {
        state.isordersLoading = true;
      })
      .addCase(getTotalOrderByClId.fulfilled, (state, action) => {
        state.totalClientOrder = action.payload.order;
        localStorage.setItem(" clientOrder", JSON.stringify(state.clientOrder));
        state.isordersLoading = false;
      })
      .addCase(getTotalOrderByClId.rejected, (state) => {
        state.isordersLoading = true;
      });
  },
});
export const {
  clearOrder,
  setpaymentUpdateOrder,
  setpaymentUpdateOrderClear,
  setcurrentOrder,
} = OrderSlice.actions;
export default OrderSlice.reducer;
