import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { Slider, Spin } from "antd";
import SingleProduct from "../../component/product/SingleProduct";
import MainFilter from "./MainFilter";

const SubcategorySearch = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { categorytotal } = useSelector((store) => store.category);

  const [current_category, setcurrent_category] = useState("");
  const [current_subcategory, setcurrent_subcategory] = useState("");
  const [selected_category_tag, setselected_category_tag] = useState([]);
  const [category_tags, setcategory_tags] = useState([]);
  const [category_products, setcategory_products] = useState([]);
  const [tag_loading, settag_Loading] = useState(true);
  const [product_loading, setproduct_Loading] = useState(true);

  const [filtered_products, setfiltered_products] = useState([]);
  const [allproductLoading, setallproductLoading] = useState(true);

  const [filterSelectedCloseArray, setfilterSelectedCloseArray] = useState([]);

  const onChange = (value) => {};
  const onChangeComplete = (value) => {};

  const selectCloserFilterPress = (value) => {
    if (filterSelectedCloseArray.includes(value)) {
      setfilterSelectedCloseArray(
        filterSelectedCloseArray.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setfilterSelectedCloseArray((filterSelectedCloseArray) => [
        ...filterSelectedCloseArray,
        value,
      ]);
    }
  };

  const getfilt_Prod = (value) => {
    setfiltered_products([...value]);
  };

  useEffect(() => {
    setproduct_Loading(true);
    setallproductLoading(true);
  }, [params.sub_category]);

  useEffect(() => {
    if (params.sub_category) {
      const get_sub_Category = async () => {
        const url = `${Baseurl}/api/v1/subcategory/slugurl/${params.sub_category}`;
        const sub_categoryRespo = await axios.get(url);
        if (sub_categoryRespo.data.success) {
          const sub_category_data = sub_categoryRespo.data.subcategory;
          setcurrent_subcategory(sub_category_data);
          const category_find = categorytotal.find(
            (data) => data.name === sub_category_data.category
          );
          setcurrent_category(category_find);
          const tag_url = `${Baseurl}/api/v1/categorytag/category-tags-by-sub-category/${sub_category_data.name}`;
          const category_tag_Respo = await axios.get(tag_url);

          if (category_tag_Respo.data.success) {
            const category_tag_data = category_tag_Respo.data.ctegoryTags;
            setcategory_tags(category_tag_data);
            settag_Loading(false);
          }

          const product_url = `${Baseurl}/api/v1/product/all-products-by-sub-category-limit/${sub_category_data.name}`;
          const product_Respo = await axios.get(product_url);

          if (product_Respo.data.success) {
            const product_data = product_Respo.data.products;
            setcategory_products(product_data);
            setfiltered_products(product_data);
            setproduct_Loading(false);
          }
        }
      };

      get_sub_Category();
    }
  }, [params.sub_category]);
  useEffect(() => {
    if (current_subcategory !== "") {
      const get_Products = async () => {
        const product_url = `${Baseurl}/api/v1/product/all-products-by-sub-category/${current_subcategory.name}`;
        const product_Respo = await axios.get(product_url);

        if (product_Respo.data.success) {
          const product_data = product_Respo.data.products;
          setcategory_products(product_data);
          setfiltered_products(product_data);
          setallproductLoading(false);
        }
      };

      get_Products();
    }
  }, [current_subcategory]);

  const handletagPress = (evt, value) => {
    if (selected_category_tag.includes(value)) {
      setselected_category_tag(
        selected_category_tag.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_category_tag((selected_category_tag) => [
        ...selected_category_tag,
        value,
      ]);
    }

    if (selected_category_tag.length === 0) {
      const products = category_products;
      setfiltered_products(products);
    } else {
      const products = category_products.filter((item) =>
        selected_category_tag.includes(item.CategoryTag)
      );
      setfiltered_products(products);
    }
  };

  return (
    <>
      <div id="page-content">
        <div className="container">
          {/* <div className="collection-slider-6items gp10 slick-arrow-dots sub-collection section pt-0">
            <SliderSubCategories />
          </div> */}
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 sidebar sidebar-bg filterbar">
              <div className="closeFilter d-block d-lg-none">
                <i className="icon anm anm-times-r" />
              </div>
              <div className="sidebar-tags sidebar-sticky clearfix">
                {/* <div className="sidebar-widget filterBox filter-widget">
                  <div className="widget-title">
                    <h2>Filter By</h2>
                  </div>
                  <div className="widget-content filterby filterDD">
                    <ul className="items tags-list d-flex-wrap">
                      <li className="item">
                        <Link to="#;" className="rounded-5">
                          <span className="filter-value">Women</span>
                          <i className="icon anm anm-times-r" />
                        </Link>
                      </li>
                      <li className="item">
                        <Link to="#;" className="rounded-5">
                          <span className="filter-value">Blue</span>
                          <i className="icon anm anm-times-r" />
                        </Link>
                      </li>
                      <li className="item">
                        <Link to="#;" className="rounded-5">
                          <span className="filter-value">XL</span>
                          <i className="icon anm anm-times-r" />
                        </Link>
                      </li>
                    </ul>
                    <Link to="#;" className="btn btn-sm brd-link">
                      Clear All
                    </Link>
                  </div>
                </div> */}
                <div className="sidebar-widget clearfix categories filterBox filter-widget">
                  <div
                    className={
                      filterSelectedCloseArray.includes("1")
                        ? "widget-title active"
                        : "widget-title"
                    }
                    onClick={() => selectCloserFilterPress("1")}
                  >
                    <h2>Categories</h2>
                  </div>
                  <div
                    className="widget-content filterDD"
                    style={
                      filterSelectedCloseArray.includes("1")
                        ? {
                            display: "none",
                          }
                        : {}
                    }
                  >
                    <ul className="sidebar-categories scrollspy morelist clearfix">
                      {tag_loading === false &&
                        category_tags &&
                        category_tags.map((tag, index) => (
                          <li key={index}>
                            <input
                              type="checkbox"
                              id="avone"
                              name="avone"
                              value={tag.name}
                              checked={selected_category_tag.includes(tag.name)}
                              onChange={(e) => handletagPress(e, tag.name)}
                            />
                            <label htmlFor="avone">
                              <span />
                              {tag.name}
                            </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="sidebar-widget filterBox filter-widget">
                  <div className="widget-title">
                    <h2>Price</h2>
                  </div>
                  <form
                    className="widget-content price-filter filterDD"
                    action="#"
                    method="post"
                  >
                    <div id="slider-range" className="mt-2" />
                    <div className="row">
                      <div className="col-12">
                        <Slider
                          range
                          step={10}
                          defaultValue={[20, 50]}
                          onChange={onChange}
                          onChangeComplete={onChangeComplete}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <MainFilter
                  current_category={current_category}
                  filterSelectedCloseArray={filterSelectedCloseArray}
                  category_products={category_products}
                  filtered_products={filtered_products}
                  selected_category_tag={selected_category_tag}
                  selectCloserFilterPress={selectCloserFilterPress}
                  getfilt_Prod={getfilt_Prod}
                />
                {/*End Price Filter*/}
                {/*Color Swatches*/}
                {/* <div className="sidebar-widget filterBox filter-widget">
                  <div className="widget-title">
                    <h2>Color</h2>
                  </div>
                  <div className="widget-content filter-color filterDD">
                    <ul className="swacth-list swatches d-flex-center clearfix pt-0">
                      <li className="swatch large radius available active">
                        <img
                          src="assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Blue"
                        />
                      </li>
                      <li className="swatch large radius available">
                        <img
                          src="assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Black"
                        />
                      </li>
                      <li className="swatch large radius available">
                        <img
                          src="assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pink"
                        />
                      </li>
                      <li className="swatch large radius available">
                        <img
                          src="assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Red"
                        />
                      </li>
                      <li className="swatch large radius available black">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Black"
                        />
                      </li>
                      <li className="swatch large radius available red">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Red"
                        />
                      </li>
                      <li className="swatch large radius available blue">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Blue"
                        />
                      </li>
                      <li className="swatch large radius available pink">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pink"
                        />
                      </li>
                      <li className="swatch large radius available gray">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Gray"
                        />
                      </li>
                      <li className="swatch large radius available green">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Green"
                        />
                      </li>
                      <li className="swatch large radius available orange">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Orange"
                        />
                      </li>
                      <li className="swatch large radius soldout yellow">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Yellow"
                        />
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/*End Color Swatches*/}
                {/*Size Swatches*/}
                {/* <div className="sidebar-widget filterBox filter-widget">
                  <div className="widget-title">
                    <h2>Size</h2>
                  </div>
                  <div className="widget-content filter-size filterDD">
                    <ul className="swacth-list size-swatches d-flex-center clearfix">
                      <li className="swatch large radius soldout">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XS"
                        >
                          XS
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="S"
                        >
                          S
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="M"
                        >
                          M
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="L"
                        >
                          L
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="X"
                        >
                          X
                        </span>
                      </li>
                      <li className="swatch large radius available active">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XL"
                        >
                          XL
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XLL"
                        >
                          XLL
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XXL"
                        >
                          XXL
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={25}
                        >
                          25
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={35}
                        >
                          35
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={40}
                        >
                          40
                        </span>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/*End Size Swatches*/}
                {/*Product Brands*/}
                {/* <div className="sidebar-widget filterBox filter-widget brand-filter">
                  <div className="widget-title">
                    <h2>Brands</h2>
                  </div>
                  <div className="widget-content filterDD">
                    <ul className="clearfix">
                      <li>
                        <input
                          type="checkbox"
                          defaultValue="avone"
                          id="avone"
                        />
                        <label htmlFor="avone">
                          <span />
                          Avone
                        </label>
                      </li>
                      <li>
                        <input type="checkbox" defaultValue="diva" id="diva" />
                        <label htmlFor="diva">
                          <span />
                          Diva
                        </label>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          defaultValue="optimal"
                          id="optimal"
                        />
                        <label htmlFor="optimal">
                          <span />
                          Optimal
                        </label>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/*End Product Brands*/}
                {/*Product Tags*/}
                {/* <div className="sidebar-widget filterBox filter-widget product-tag">
                  <div className="widget-title">
                    <h2>Product Tags</h2>
                  </div>
                  <div className="widget-content filterDD">
                    <ul className="tags-list product-tags d-flex-wrap clearfix">
                      <li className="item active">
                        <Link className="rounded-5" to="#">
                          Women
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Shoes
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Beauty
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Accessories
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          ₹100 - ₹400
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Above ₹800
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Black
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Blue
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          Red
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          M
                        </Link>
                      </li>
                      <li className="item">
                        <Link className="rounded-5" to="#">
                          XS
                        </Link>
                      </li>
                    </ul>
                    <span className="btn btn-sm brd-link btnview">
                      View all
                    </span>
                  </div>
                </div> */}
                {/*End Product Tags*/}
                {/*Banner*/}
                {/* <div className="sidebar-widget static-banner p-0">
                  <Link to="shop-left-sidebar.html">
                    <img
                      className="rounded-0 blur-up lazyload"
                      data-src="assets/images/banners/shop-banner.jpg"
                      src="assets/images/banners/shop-banner.jpg"
                      alt="image"
                      width={274}
                      height={367}
                    />
                  </Link>
                </div> */}
                {/*End Banner*/}
              </div>
            </div>
            {/*End Sidebar*/}
            {/*Products*/}
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 main-col">
              {/*Toolbar*/}
              <div className="toolbar toolbar-wrapper shop-toolbar">
                <div className="row align-items-center">
                  {/* <div className="col-4 col-sm-2 col-md-4 col-lg-4 text-left filters-toolbar-item d-flex order-1 order-sm-0">
                    <button
                      type="button"
                      className="btn btn-filter icon anm anm-sliders-hr d-inline-flex d-lg-none me-2"
                    >
                      <span className="d-none">Filter</span>
                    </button>
                  </div> */}
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-right filters-toolbar-item d-flex justify-content-end order-2 order-sm-2">
                    <div className="filters-item d-flex align-items-center ms-2 ms-lg-3">
                      <label
                        htmlFor="SortBy"
                        className="mb-0 me-2 text-nowrap d-none"
                      >
                        Sort by:
                      </label>
                      <select
                        name="SortBy"
                        id="SortBy"
                        className="filters-toolbar-sort"
                      >
                        <option value="featured" selected="selected">
                          Featured
                        </option>
                        <option value="best-selling">Best selling</option>
                        <option value="title-ascending">
                          Alphabetically, A-Z
                        </option>
                        <option value="title-descending">
                          Alphabetically, Z-A
                        </option>
                        <option value="price-ascending">
                          Price, low to high
                        </option>
                        <option value="price-descending">
                          Price, high to low
                        </option>
                        <option value="created-ascending">
                          Date, old to new
                        </option>
                        <option value="created-descending">
                          Date, new to old
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/*End Toolbar*/}
              {/*Product Grid*/}

              {product_loading === true ? (
                <>
                  <div className="example">
                    <Spin />
                  </div>
                </>
              ) : (
                <>
                  <div className="grid-products grid-view-items">
                    <div className="row col-row product-options row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                      {filtered_products &&
                        filtered_products.map((product, index) => (
                          <SingleProduct key={index} product={product} />
                        ))}
                      {product_loading === false &&
                        allproductLoading === true && (
                          <div className="example_new">
                            <Spin />
                          </div>
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubcategorySearch;
